import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import SnackbarNotification from 'components/SnackbarNotification';

import React, { useEffect, useState } from 'react';
import TableTitulos from '../utils/TableEditavel';
import InfosRecebimento from '../utils/InfosRecebimento';
import api from 'services/api';
import { getHashEmpresa } from 'util/cobUtils';
import { format, parse } from 'date-fns';
import { useSelector } from 'react-redux';
import Loading from 'components/Utils/loading';

export default function LancarRecebimento({
  open,
  onClose,
  rowSelecionada,
  listarAlegaPagamentos,
}) {
  const [alertInfoTipo, setAlertInfoTipo] = useState();
  const [openAlert, setOpenAlert] = useState(false);
  const [alertInfo, setAlertInfo] = useState();
  const [loading, setLoading] = useState(false);
  const [disableConfirmar, setDisableConfirmar] = useState(false);
  const [titulos, setTitulos] = useState([]);
  const [planoDeConta, setPlanoDeConta] = useState();
  const [formaPagto, setFormaPagto] = useState();
  const [listaPlanodeConta, setListaPlanodeConta] = useState([]);
  const [listaFormaPagto, setListaFormaPagto] = useState([]);
  const [dataRecebimento, setDataRecebimento] = useState(null);
  const [negociadorDoProcesso, setNegociadorDoProcesso] = useState('');
  const [idHashRecebimento, setIdHashRecebimento] = useState('');
  const [dadosRecebimento, setDadosRecebimento] = useState({});

  const authUserInfo = useSelector(({ auth }) => auth.authUserInfo);

  useEffect(() => {
    if (open && rowSelecionada) {
      const fetchData = async () => {
        try {
          const { id_uuid, titulos_alega } = rowSelecionada;
          let response;
          if (titulos_alega) {
            const { status, titulosAlega } = titulos_alega;
            if (status === 'CONFIRMADO') {
              const ids = titulosAlega.map((tit) => `'${tit.hash_titulo}'`);
              const idsFormatado = `(${`${ids}`.replace('[]', '')})`;

              response = await api.post(
                `carregar_titulos_confirmados/${id_uuid}`,
                { idsFormatado }
              );
            } else {
              response = await api.get(`carregar_titulos/${id_uuid}`);
            }
          }
          setTitulos(response.data.rows);
        } catch (error) {
          setAlertInfo(
            'Títulos não localizados!'
          );
          setAlertInfoTipo('mb-3 bg-danger');
          setOpenAlert(true);
        }
      };
      fetchData();
      listarFormaPagto();
      listarPlanoDeContas();

      if (rowSelecionada.hash_recebimento) {
        setDisableConfirmar(true);
      }
    }
  }, [open, rowSelecionada]);

  useEffect(() => {
    if (titulos.length > 0) buscarNegociadorDoProcesso();
  }, [titulos]);

  useEffect(() => {
    if (idHashRecebimento) {
      carregarRecebimento();
    }
  }, [idHashRecebimento]);

  useEffect(() => {
    if (Object.keys(dadosRecebimento).length > 0) {
      confirmarBaixa();
    }
  }, [dadosRecebimento]);

  function handleSelectPlanoDeConta(planoSelecionado) {
    setPlanoDeConta(planoSelecionado);
  }

  function handleSelectFormaPagto(formaPagtoSelecionado) {
    setFormaPagto(formaPagtoSelecionado);
  }

  function handleChangeDataRecebimento(dataRec) {
    setDataRecebimento(dataRec);
  }

  async function listarFormaPagto() {
    const hashEmpresa = getHashEmpresa();
    const flag = 'FORMA_PAGTO';

    setLoading(true);
    const response = await api.get(
      `/listar_forma_pagto/${hashEmpresa}?flag=${flag}`
    );
    setLoading(false);
    setListaFormaPagto(response.data);
  }

  async function listarPlanoDeContas() {
    const hashEmpresa = getHashEmpresa();
    const response = await api.get(`/listar_plano_conta/${hashEmpresa}`);
    const data = await response.data.rows;
    setListaPlanodeConta(data);
  }

  async function buscarNegociadorDoProcesso() {
    const response = await api.get(
      `buscar_negociador/${titulos[0].iddevedor}?processo=${titulos[0].processo}`
    );
    const data = response.data.rows;
    if (response.status === 200 && data.length > 0) {
      const negociador = data[0].cobrador;
      setNegociadorDoProcesso(negociador);
    }
  }

  async function carregarRecebimento() {
    const hashEmpresa = getHashEmpresa();

    const response = await api.get(
      `/carregar_recebimento_liberado/${hashEmpresa}?idRecebimento=${idHashRecebimento}`
    );
    const [data] = await response.data.rows;

    if (data) setDadosRecebimento(data);
  }

  async function handleFaturarRecebimento() {
    const data = {};
    const dataConfirmacao = format(
      parse(titulos[0].data_confirmado, 'dd/MM/yyyy', new Date()),
      'yyyy-MM-dd'
    );

    const titulosConfirmado = titulos[0].titulos_alega;
    const hashAlega = titulos[0].id_uuid;

    try {
      if (!planoDeConta) {
        throw new Error('Selecione um plano de conta !');
      }
      data.tx_data_rec = dataRecebimento || dataConfirmacao;
      data.tx_rec_valor = titulosConfirmado.totalPago;
      data.tx_credor = titulos[0].idcredor;
      data.tx_devedor = titulos[0].iddevedor;
      data.combo_conta = 0;
      data.combo_plano_conta = planoDeConta;
      data.tx_processo = titulos[0].processo;

      data.tx_forma_pagto = formaPagto || 'BOLETO';
      data.tx_local_pagto = 'CREDORA';

      data.idacordoneg = null;
      data.idacordoneg_vinculou = false;

      if (titulosConfirmado.totalPago <= 0) {
        setLoading(false);
        setAlertInfo('Erro. Valor do recebimento está zerado.');
        setAlertInfoTipo('mb-3 bg-danger');
        setOpenAlert(true);
        return;
      }

      let usuario = '';
      if (authUserInfo != null) {
        usuario = authUserInfo.ac_login;
      }
      data.usuario_cad = usuario;
      data.hash_alega = hashAlega;

      const data2 = JSON.stringify(data, null, 2);

      setLoading(true);

      const response = await api.post(`/lancar_recebimentos_alega`, { data2 });

      if (response.status == 200) {
        setIdHashRecebimento(response.data.hash);
      }
    } catch (error) {
      setAlertInfo(error.message);
      setAlertInfoTipo('mb-3 bg-danger');
      setOpenAlert(true);
      setLoading(false);
    }
  }

  async function confirmarBaixa() {
    const dadosBaixa = [];

    const arrayTitulosOriginais = [];
    for (let index = 0; index < titulos.length; index++) {
      const element = titulos[index];

      if (element.titulos_alega.totalPago > 0) {
        const titAtual = element.titulos_alega.titulosAlega.find(
          (tit) => tit.hash_titulo === element.hash_titulo
        );
        const isQuitacaoTotal =
          parseFloat(titAtual.valor_pago) >= parseFloat(titAtual.valor);

        const itemBaixa = {};
        itemBaixa.idtitulo = element.hash_titulo;
        itemBaixa.negociador = negociadorDoProcesso;
        itemBaixa.capitalpago = titAtual.valor_pago;
        itemBaixa.descontopago = 0;
        itemBaixa.protestopago = 0;
        itemBaixa.jurospago = 0;
        itemBaixa.multapago = 0;
        itemBaixa.honorariospago = 0;
        itemBaixa.taxaadmpago = 0;
        itemBaixa.taxaoutpago = 0;
        itemBaixa.jurosretpago = 0;
        itemBaixa.comihonpago = 0;
        itemBaixa.jurosmorapago = 0;
        itemBaixa.totalpago = titAtual.valor_pago;
        itemBaixa.taxapago = 0;
        itemBaixa.pdpago = titAtual.valor_pd;
        itemBaixa.jurosatrasopago = 0;
        itemBaixa.origem = 1;
        itemBaixa.quitacaotot = isQuitacaoTotal;
        itemBaixa.obs = '';
        dadosBaixa.push(itemBaixa);
        arrayTitulosOriginais.push(element);
      }
    }

    try {
      let usuario = '';
      if (authUserInfo != null) {
        usuario = authUserInfo.ac_login;
      }

      const data = {};
      data.idrecebimento = idHashRecebimento;
      data.baixas = dadosBaixa;
      data.usuario_cad = usuario;
      data.fase = dadosRecebimento.fase;
      data.objetos_originais = arrayTitulosOriginais;
      data.objetos_alterados = arrayTitulosOriginais;

      const data2 = JSON.stringify(data, null, 2);
      const response = await api.post(`/baixar_recebimentos_alega`, {
        data2,
      });
      setLoading(false);

      if (response.status == 200) {
        setAlertInfo(`Baixa realizada com sucesso`);
        setAlertInfoTipo('mb-3 bg-success');
        setOpenAlert(true);
        onClose();
        listarAlegaPagamentos();
      }
    } catch (error) {
      if (error.response) {
        setLoading(false);
        setAlertInfo('Erro. Consulte o suporte técnico.');
        setAlertInfoTipo('mb-3 bg-danger');
        setOpenAlert(true);
      }
    }
  }

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
        <DialogTitle
          style={{
            color: '#fff',
            textTransform: 'uppercase',
            whiteSpace: 'nowrap',
            fontSize: '16px',
          }}
        >
          Lançar recebimento
        </DialogTitle>
        <DialogContent style={{ paddingTop: 10 }}>
          <InfosRecebimento
            titulos={titulos}
            handleSelectPlanoDeConta={handleSelectPlanoDeConta}
            handleSelectFormaPagto={handleSelectFormaPagto}
            handleChangeDataRecebimento={handleChangeDataRecebimento}
            listarFormaPagto={listarFormaPagto}
            listarPlanoDeConta={listarPlanoDeContas}
            formasPagto={listaFormaPagto}
            planosDeConta={listaPlanodeConta}
          />
          <TableTitulos titulos={titulos} />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            style={{
              color: disableConfirmar ? 'gray' : '#1db522',
              borderColor: disableConfirmar ? 'gray' : '#1db522',
            }}
            onClick={handleFaturarRecebimento}
            disabled={disableConfirmar}
          >
            Confirmar
          </Button>
          <Button
            style={{ color: '#000' }}
            variant="text"
            onClick={() => onClose()}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      <SnackbarNotification
        openAlert={openAlert}
        alertInfoTipo={alertInfoTipo}
        alertInfo={alertInfo}
        callbackParent={() => setOpenAlert(false)}
      />
      <Loading carregar={loading} />
    </>
  );
}
