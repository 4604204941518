import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  Avatar,
  Menu,
  MenuItem,
  Divider,
  Typography,
  Grid,
  Switch,
} from '@material-ui/core';

import { setThemeColor } from 'actions/index';

import {
  DARK_THEME_COLOR,
  AMBER,
  BLUE,
  DEEP_ORANGE,
  GREEN,
  PINK,
  INDIGO,
  CYAN,
  DARK_CYAN,
  DARK_DEEP_ORANGE,
  DARK_AMBER,
  DARK_GREEN,
  DARK_INDIGO,
  DARK_PINK,
  DARK_BLUE,
} from 'constants/ThemeColors';

import { userSignOut } from 'actions/Auth';
import IntlMessages from 'util/IntlMessages';
import AlterarSenha from '../../containers/AlterarSenha';
import DialogCadastro2FA from '../../app/routes/cadastros/colaboradores/Dialogs/DialogCadastro2FA';
import SnackbarNotification from 'components/SnackbarNotification';
import Lock from '@material-ui/icons/Lock';
import api from 'services/api';

const UserInfo = () => {
  const dispatch = useDispatch();

  const [anchorE1, setAnchorE1] = useState(null);
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertInfo, setAlertInfo] = useState();
  const [alertInfoTipo, setAlertInfoTipo] = useState();
  const [isOpenAlterarSenha, setIsOpenAlterarSenha] = useState(false);
  const [isOpen2FA, setIsOpen2FA] = useState(false);
  const [checked2FA, setChecked2FA] = useState('');
  const [token2FA, setToken2FA] = useState('');
  const [usuario, setUsuario] = useState('');
  const [razaoEmp, setRazaoEmp] = useState('');
  const [ckDarkSideBar, setCkDarkSideBar] = useState(false);

  const { themeColor } = useSelector(({ settings }) => settings);
  const authUserInfo = useSelector(({ auth }) => auth.authUserInfo);

  const handleThemeColor = (colorCode) => {
    dispatch(setThemeColor(colorCode));
    const body = document.body.classList;
    body.remove(themeColor);
    body.remove('dark-theme');
    body.add(colorCode);
  };

  useEffect(() => {
    if (authUserInfo?.ac_login) {
      setChecked2FA(authUserInfo.ativa_2f2 ? `Ativo` : `Inativo`);
      setUsuario(authUserInfo.ac_login);
      setRazaoEmp(authUserInfo.conta_empresa);

      const sideBarDark = localStorage.getItem('sidebar_dark') === 'true';
      setCkDarkSideBar(sideBarDark);
    }
  }, [authUserInfo]);

  // mudança de cores dos menus
  const location = useLocation();
  const pathFullMenu = location.pathname;
  const pathMenuPos = pathFullMenu.indexOf('/', 6);
  let pathFull = pathFullMenu;
  if (pathMenuPos > 0) {
    pathFull = pathFullMenu.substring(0, pathMenuPos);
  }

  useEffect(() => {
    if (authUserInfo?.ac_login) {
      localStorage.setItem('sidebar_dark', ckDarkSideBar);

      if (pathFull == '/app/config') {
        if (ckDarkSideBar) handleThemeColor(DARK_DEEP_ORANGE);
        else handleThemeColor(DEEP_ORANGE);
      } else if (pathFull == '/app/rel') {
        if (ckDarkSideBar) handleThemeColor(DARK_AMBER);
        else handleThemeColor(AMBER);
      } else if (pathFull == '/app/cad') {
        if (ckDarkSideBar) handleThemeColor(DARK_CYAN);
        else handleThemeColor(CYAN);
      } else if (pathFull == '/app/backof') {
        if (ckDarkSideBar) handleThemeColor(DARK_INDIGO);
        else handleThemeColor(INDIGO);
      } else if (pathFull == '/app/fin') {
        if (ckDarkSideBar) handleThemeColor(DARK_GREEN);
        else handleThemeColor(GREEN);
      } else if (pathFull == '/app/com') {
        if (ckDarkSideBar) handleThemeColor(DARK_PINK);
        else handleThemeColor(PINK);
      } else {
        if (ckDarkSideBar) handleThemeColor(DARK_BLUE);
        else handleThemeColor(BLUE);
      }
    }
  }, [authUserInfo, ckDarkSideBar]);

  const hanfleSalvarInfo2FA = async (tokenSecret) => {
    try {
      const code2FAAux = checked2FA == 'Ativo' ? true : false;
      const response = await api.post(
        `/salvar_info_2fa_menu/${authUserInfo.id_uuid}`,
        {
          data: {
            code2FA: code2FAAux,
            tokenSecret,
          },
        }
      );

      if (response.status == 200) {
        setAlertInfo('Autenticação 2FA Aplicada com Sucesso!');
        setAlertInfoTipo('mb-3 bg-success');
        setOpenAlert(true);
      }
    } catch (error) {
      setAlertInfo('Erro ao Aplicar Autenticação 2FA.');
      setAlertInfoTipo('mb-3 bg-danger');
      setOpenAlert(true);
    }
  };

  const handleClick = (event) => {
    setOpen(true);
    setAnchorE1(event.currentTarget || null);
  };

  const handleRequestClose = () => {
    setOpen(false);
  };

  const cancelarAlterarSenha = () => {
    setIsOpenAlterarSenha(false);
  };

  const salvarAlterarSenha = () => {
    setIsOpenAlterarSenha(false);
  };

  const handleClose2FA = () => {
    setIsOpen2FA(false);
  };

  return (
    <div className="user-profile d-flex flex-row align-items-center">
      <Avatar
        alt="..."
        src={
          authUserInfo?.white_label
            ? null
            : require('assets/images/logo-cubo.png')
        }
        className="user-avatar"
      />
      <div className="user-detail">
        <h4 className="user-name" onClick={handleClick}>
          {authUserInfo ? authUserInfo.ac_login : 'OPERADOR'}{' '}
          <i className="zmdi zmdi-caret-down zmdi-hc-fw align-middle" />
        </h4>
      </div>
      <Menu
        className="user-info"
        id="simple-menu"
        anchorEl={anchorE1}
        open={open}
        onClose={handleRequestClose}
        PaperProps={{
          style: {
            minWidth: 120,
            paddingTop: 0,
            paddingBottom: 0,
          },
        }}
      >
        <div className="pl-3 pr-3">
          <Typography
            variant="caption"
            display="block"
            gutterBottom
            style={{ fontWeight: '700' }}
          >
            Olá,
          </Typography>
          <Typography
            variant="caption"
            display="block"
            gutterBottom
            style={{ fontWeight: '700' }}
          >
            {authUserInfo
              ? (authUserInfo.nome || '').substring(0, 25)
              : 'OPERADOR'}
          </Typography>
        </div>
        <Divider />
        {/*
        <MenuItem onClick={handleRequestClose}>
          <i className="zmdi zmdi-account zmdi-hc-fw mr-2" />
          Meu Perfil
        </MenuItem>
        */}
        <MenuItem
          style={{ fontWeight: '300' }}
          onClick={() => {
            setIsOpenAlterarSenha(true);
            handleRequestClose();
          }}
        >
          <i className="zmdi zmdi-key zmdi-hc-fw mr-2" />
          Alterar minha senha
        </MenuItem>
        <MenuItem
          style={{ fontWeight: '300' }}
          onClick={() => {
            setIsOpen2FA(true);
            handleRequestClose();
          }}
        >
          <Lock fontSize="small" className="mr-2" style={{ fontSize: 15 }} />
          Ativar autenticação de 2 fatores
        </MenuItem>
        <Divider />

        <div className="pl-3 pr-3 pt-2">
          <Typography
            variant="caption"
            display="block"
            style={{ fontWeight: '700' }}
          >
            Personalizar Menu
          </Typography>

          <Typography component="div" className="pb-2">
            <Grid component="label" container alignItems="center" spacing={1}>
              <Grid item>
                <div hidden={ckDarkSideBar}>
                  <i className="zmdi zmdi-brightness-2 zmdi-hc-fw mr-2"></i>
                  <Typography variant="caption" style={{ fontWeight: '300' }}>
                    Modo escuro na barra lateral
                  </Typography>
                </div>
                <div hidden={!ckDarkSideBar}>
                  <i className="zmdi zmdi-brightness-5 zmdi-hc-fw mr-2"></i>
                  <Typography variant="caption" style={{ fontWeight: '300' }}>
                    Modo claro na barra lateral
                  </Typography>
                </div>
              </Grid>
              <Grid item>
                <Switch
                  size="small"
                  name="ck_ativo"
                  checked={ckDarkSideBar}
                  onClick={(e) => {
                    setCkDarkSideBar(e.target.checked);
                    handleRequestClose();
                  }}
                />
              </Grid>
            </Grid>
          </Typography>
        </div>

        <Divider />
        <MenuItem
          style={{ fontWeight: '300' }}
          onClick={() => {
            handleRequestClose();
            dispatch(userSignOut());
          }}
        >
          <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2" />
          Deslogar do sistema
        </MenuItem>
      </Menu>

      <AlterarSenha
        isOpen={isOpenAlterarSenha}
        callbackParentFechar={() => cancelarAlterarSenha()}
        callbackParentSalvar={() => salvarAlterarSenha()}
      />

      <DialogCadastro2FA
        openCadastro2FA={isOpen2FA}
        close={handleClose2FA}
        nome2FA={{
          usuario,
          razaoEmp,
        }}
        setToken2FA={setToken2FA}
        onChangeChecked2FA={setChecked2FA}
        getChecked2FA={checked2FA}
        display={'menu'}
        hanfleSalvarInfo2FA={hanfleSalvarInfo2FA}
      />

      <SnackbarNotification
        openAlert={openAlert}
        alertInfoTipo={alertInfoTipo}
        alertInfo={alertInfo}
        callbackParent={() => setOpenAlert(false)}
      />
    </div>
  );
};

export default UserInfo;
