import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

export default function EstornarRecebimento(props) {
  const {
    isEstornarRecebimentoOpen,
    handleFecharEstornar,
    confirmaEstornarRecebimentoLiberado,
  } = props;
  return (
    <Dialog
      open={isEstornarRecebimentoOpen}
      onClose={handleFecharEstornar}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Confirmar estorno</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Confirma o estorno do registro selecionado?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={confirmaEstornarRecebimentoLiberado} color="primary">
          Confirmar Estorno
        </Button>
        <Button onClick={handleFecharEstornar} autoFocus>
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
