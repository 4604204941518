import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { getHashEmpresa } from 'util/cobUtils';
import Loading from 'components/Utils/loading';

import api from 'services/api';

import SnackbarNotification from 'components/SnackbarNotification';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    TextField,
} from '@material-ui/core';

const CancelarPagamento = ({
    abrirCancelar,
    callbackParentFechar,
    hashEmpresa,
    callbackParentConfirmarCancelar,
    rowsSelecionadas,
}) => {
    const authUserInfo = useSelector(({ auth }) => auth.authUserInfo);

    const [openAlert, setOpenAlert] = useState(false);
    const [alertInfo, setAlertInfo] = useState();
    const [alertInfoTipo, setAlertInfoTipo] = useState();
    const [loading, setLoading] = useState(false);

    const [ativaBotaoConfirma, setAtivaBotaoConfirma] = useState(true);

    const handleClickCloseAlert = () => {
        setOpenAlert(false);
    };

    async function confirmarCancelar() {
        setAtivaBotaoConfirma(false);
        setLoading(true)
    try {

        const data = {};
        const ids = rowsSelecionadas.map(item => item.id);
        data.idscheckboxAlega = ids
        const data2 = JSON.stringify(data, null, 2);

        const response = await api.post(`/cancelar_pagamento_alega/${hashEmpresa}`,
            {data2}
        );


        if (response.status === 200) {
            setLoading(false);
    
            setAlertInfo('Pagamentos cancelados com sucesso');
            setAlertInfoTipo('mb-3 bg-success');
            setOpenAlert(true);
            setAtivaBotaoConfirma(true);
    
            callbackParentConfirmarCancelar()
          }
        
    } catch (error) {
        if (error.message) setAlertInfo(error.message, 'Ahhh');
      // throw new Error
      else setAlertInfo('Erro. Consulte o suporte técnico.');
      setAlertInfoTipo('mb-3 bg-danger');
      setOpenAlert(true);
      setAtivaBotaoConfirma(true);
      setLoading(false);
    }

    }

    return (
        <>
            <Dialog
                open={abrirCancelar}
                onClose={() => callbackParentFechar()}
                aria-labelledby="form-dialog-title"
            >
                    <div>
                        <DialogTitle id="form-dialog-title">
                            Cancelar {rowsSelecionadas.length > 1 ? ' Pagamentos' : 'Pagamento'}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                            Tem certeza que deseja cancelar os pagamentos selecionados?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                disabled={!ativaBotaoConfirma}
                                onClick={confirmarCancelar}
                                color="primary"
                            >
                                Confirmar
                            </Button>
                            <Button onClick={() => callbackParentFechar()}>Fechar</Button>
                        </DialogActions>
                    </div>
            </Dialog>

            <SnackbarNotification
                openAlert={openAlert}
                alertInfoTipo={alertInfoTipo}
                alertInfo={alertInfo}
                callbackParent={() => handleClickCloseAlert()}
            />

            <Loading carregar={loading} />
        </>
    );
};

export default CancelarPagamento;