import React, { useState } from 'react';
import { Link, withRouter, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { AppBar, Toolbar, IconButton, Tooltip } from '@material-ui/core';

import ExitToApp from '@material-ui/icons/ExitToApp';
import HeadsetMic from '@material-ui/icons/HeadsetMic';
import FolderShared from '@material-ui/icons/FolderShared';
import AttachMoney from '@material-ui/icons/AttachMoney';
import SupervisedUserCircle from '@material-ui/icons/SupervisedUserCircle';
import MessageIcon from '@material-ui/icons/Message';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

import { useSnackbar } from 'notistack';

import {
  // CONNECTION_WS,
  // DRAWER_TYPE,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  MINI_DRAWER,
} from 'constants/ActionTypes';

import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

import SearchBox from 'components/SearchBox';

import {
  switchLanguage,
  // toggleCollapsedNav,
  setDrawerType,
} from 'actions/Setting';
// import IntlMessages from 'util/IntlMessages';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
// import LanguageSwitcher from "components/LanguageSwitcher/index";

import { setDarkTheme, setThemeColor } from 'actions/index';
// import { AMBER, BLUE, DEEP_ORANGE, GREEN, INDIGO } from 'constants/ThemeColors';
import { userSignOut } from 'actions/Auth';
import api from 'services/api';
import { getHashEmpresa } from 'util/cobUtils';
import { getControleDeAcesso } from 'util/AuthUtils';

const Index = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { drawerType, locale } = useSelector(({ settings }) => settings);
  const [langSwitcher, setLangSwitcher] = useState(false);
  const [mailNotification, setMailNotification] = useState(false);
  const [appNotification, setAppNotification] = useState(false);
  const [searchBox, setSearchBox] = useState(false);
  const [apps, setApps] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [discarText, setDiscarText] = useState('');
  const [menuTipo, setmenuTipo] = useState(true);

  const { themeColor, darkTheme } = useSelector(({ settings }) => settings);

  const authUserInfo = useSelector(({ auth }) => auth.authUserInfo);

  /*
  const onAppNotificationSelect = () => {
    setAppNotification(!appNotification);
  };

  const onMailNotificationSelect = () => {
    setMailNotification(!mailNotification);
  };
  
  const onLangSwitcherSelect = () => {
    setLangSwitcher(!langSwitcher);
  };
  */

  const onSearchBoxSelect = () => {
    setSearchBox(!searchBox);
  };
  const onAppsSelect = () => {
    setApps(!apps);
  };

  /*
  const handleRequestClose = () => {
    setSearchBox(false);
    setLangSwitcher(false);
    setMailNotification(false);
    setSearchBox(false);
    setApps(false);
  };
*/
  const onToggleCollapsedNav = (e) => {
    if (menuTipo === false) {
      setmenuTipo(true);
      setMiniDrawer();
    } else {
      setmenuTipo(false);
      setFixedDrawer();
    }
  };

  /*
  const handleThemeColor = (colorCode) => {
    dispatch(setThemeColor(colorCode));
    const body = document.body.classList;
    body.remove(themeColor);
    body.remove('dark-theme');
    body.add(colorCode);
  };
  */

  const handleDarkTheme = () => {
    dispatch(setDarkTheme());
    const body = document.body.classList;
    body.toggle(themeColor);
    body.toggle('dark-theme');
  };

  const setFixedDrawer = () => {
    dispatch(setDrawerType(FIXED_DRAWER));
  };

  const setMiniDrawer = () => {
    dispatch(setDrawerType(MINI_DRAWER));
  };

  const Apps = () => {
    return (
      <ul className="jr-list jr-list-half">
        {getControleDeAcesso('rel', authUserInfo) && (
          <li className="jr-list-item">
            <Link className="jr-list-link" to="/app/rel/inicio">
              <i className="fa fa-file-text-o" />
              <span className="jr-list-text">Relatórios</span>
            </Link>
          </li>
        )}

        {getControleDeAcesso('comercial', authUserInfo) && (
          <li className="jr-list-item">
            <Link className="jr-list-link" to="/app/com/inicio">
              <i className="fa fa-calendar" />
              <span className="jr-list-text">Comercial</span>
            </Link>
          </li>
        )}

        {authUserInfo?.white_label == false &&
          getControleDeAcesso('bi', authUserInfo) && (
            <li className="jr-list-item">
              <a
                className="jr-list-link"
                target="_blank"
                href="https://bi.cob.cloud/"
              >
                <i className="fa fa-bar-chart" />
                <span className="jr-list-text">CobCloud&nbsp;BI</span>
              </a>
            </li>
          )}

        {getControleDeAcesso('conf', authUserInfo) && (
          <li className="jr-list-item">
            <Link className="jr-list-link" to="/app/config/inicio">
              <i className="fa fa-cogs" />
              <span className="jr-list-text">Configurações</span>
            </Link>
          </li>
        )}

        {getControleDeAcesso('funcionalidade_pro', authUserInfo) && (
          <li className="jr-list-item">
            <a className="jr-list-link text-warning">
              <i className="fa fa-star" />
              <span className="jr-list-text">Funcionali- dades PRÓ</span>
            </a>
          </li>
        )}
      </ul>
    );
  };

  const onSwitchLanguage = (lang) => {
    dispatch(switchLanguage(lang));
  };

  const updateSearchText = (e) => {
    setSearchText(e.target.value);
  };
  const updateDiscarText = (e) => {
    setDiscarText(e.target.value);
  };

  // localiza o processo e abre o mesmo
  async function localizarProcesso(processo) {
    let nProcesso = '';
    if (processo == '') nProcesso = searchText;
    else nProcesso = processo;

    if (nProcesso != '') {
      const hashEmpresa = getHashEmpresa();
      const response = await api.get(
        `/localizar_processo/${hashEmpresa}?processo=${nProcesso}`
      );
      const data = await response.data.rows;
      if (data.length > 0) {
        if (data[0].status_credor === 'ATIVO') {
          const uid = data[0].iddev;

          /*
        const fone = '48999485190';
        const callfilename = '1234';
        history.push(`/app/cob/processo/${uid}`, {
          id: uid,
          fone,
          fileNameChamada: callfilename,
        });
        */
          history.push(`/app/cob/processo/${uid}`, { id: uid });
        } else if (data[0].status_credor === 'INATIVO') {
          enqueueSnackbar('Atenção. Processo de Credor INATIVO!', {
            variant: 'warning',
          });
        }
      } else {
        enqueueSnackbar('Atenção. Processo não Localizado!', {
          variant: 'warning',
        });
      }
    }
  }

  const drawerStyle = drawerType.includes(FIXED_DRAWER)
    ? 'd-block d-xl-none'
    : drawerType.includes(COLLAPSED_DRAWER)
    ? 'd-block'
    : 'd-none';

  return (
    <AppBar className="app-main-header">
      <Toolbar className="app-toolbar" disableGutters={false}>
        <IconButton
          className="jr-menu-icon mr-3 d-block"
          aria-label="Menu"
          onClick={onToggleCollapsedNav}
        >
          <span className="menu-icon" />
        </IconButton>

        <SearchBox
          styleName="d-none d-lg-block ml-0 mr-3"
          placeholder2="LOCALIZAR PROCESSO"
          onChange={updateSearchText}
          onClick={() => localizarProcesso('')}
          onKeyPress={(ev) => {
            if (ev.key == 'Enter') {
              localizarProcesso(ev.target.value);
            }
          }}
          value={searchText}
        />

        {/*
        <DialogWhatsApp
          open={openDialogWhatsApp}
          closeParent={() => setOpenDialogWhatsApp(false)}
          typeConnection="geral"
        />
        */}

        {/*
        <div>
          <FormControlLabel
            control={
              <Switch
                checked={darkTheme}
                onChange={handleDarkTheme}
                name="checkedB"
                color="secondary"
              />
            }
            label="Modo Escuro"
          />
        </div>
        */}

        <ul className="header-notifications list-inline ml-auto">
          <li className="list-inline-item mail-tour">
            {/*
            <Tooltip title="WhatsApp" arrow style={{ position: 'relative' }}>
              <IconButton
                className="icon-btn"
                onClick={() => {
                  setOpenDialogWhatsApp(true);
                }}
              >
                <WhatsAppIcon style={{ color: '#fff' }} />
                <span
                  id="notificacaoWpp"
                  hidden={!(!openDialogWhatsApp && openNotification)}
                />
              </IconButton>
            </Tooltip>
              */}
            <Tooltip title="Cobrança" arrow>
              <Link className="jr-list-link" to="/app/cob/inicio">
                <IconButton className="icon-btn">
                  <HeadsetMic style={{ color: '#fff' }} />
                </IconButton>
              </Link>
            </Tooltip>
            {getControleDeAcesso('cad', authUserInfo) && (
              <Tooltip title="Cadastros" arrow>
                <Link className="jr-list-link" to="/app/cad/inicio">
                  <IconButton className="icon-btn">
                    <FolderShared style={{ color: '#fff' }} />
                  </IconButton>
                </Link>
              </Tooltip>
            )}
            {getControleDeAcesso('fin', authUserInfo) && (
              <Tooltip title="Financeiro" arrow>
                <Link className="jr-list-link" to="/app/fin/inicio">
                  <IconButton className="icon-btn">
                    <AttachMoney style={{ color: '#fff' }} />
                  </IconButton>
                </Link>
              </Tooltip>
            )}
            {getControleDeAcesso('back', authUserInfo) && (
              <Tooltip title="BackOffice" arrow>
                <Link className="jr-list-link" to="/app/backof/inicio">
                  <IconButton className="icon-btn">
                    <SupervisedUserCircle style={{ color: '#fff' }} />
                  </IconButton>
                </Link>
              </Tooltip>
            )}
          </li>

          <li
            className="list-inline-item"
            hidden={
              authUserInfo == null
                ? true
                : authUserInfo.perfil == 'NEGOCIADOR' ||
                  authUserInfo.unificar_grupo
            }
          >
            <Dropdown
              className="quick-menu app-notification"
              isOpen={apps}
              toggle={onAppsSelect}
            >
              <DropdownToggle
                className="d-inline-block"
                tag="span"
                data-toggle="dropdown"
              >
                <span className="app-notification-menu pt-1 pb-1">
                  <i className="zmdi zmdi-apps zmdi-hc-fw zmdi-hc-lg" />
                  <span>Apps</span>
                </span>
              </DropdownToggle>

              <DropdownMenu>{Apps()}</DropdownMenu>
            </Dropdown>
          </li>

          {/*
          <li className="d-inline-block d-lg-none list-inline-item">
            <Dropdown
              className="quick-menu nav-searchbox"
              isOpen={searchBox}
              toggle={onSearchBoxSelect}
            >
              <DropdownToggle
                className="d-inline-block"
                tag="span"
                data-toggle="dropdown"
              >
                <IconButton className="icon-btn">
                  <i className="zmdi zmdi-search zmdi-hc-fw" />
                </IconButton>
              </DropdownToggle>

              <DropdownMenu right className="p-0">
                <SearchBox
                  styleName="search-dropdown"
                  placeholder2="LOCALIZAR PROCESSO"
                  onChange={updateSearchText}
                  onKeyPress={(ev) => {
                    if (ev.key == 'Enter') {
                      localizarProcesso(ev.target.value);
                    }
                  }}
                  value={searchText}
                />
              </DropdownMenu>
            </Dropdown>
          </li>
                */}

          {/*
            <li className="list-inline-item">
              <Dropdown
                className="quick-menu"
                isOpen={langSwitcher}
                toggle={onLangSwitcherSelect}>

                <DropdownToggle
                  className="d-inline-block"
                  tag="span"
                  data-toggle="dropdown">
                  <IconButton className="icon-btn">
                    <i className={`flag flag-24 flag-${locale.icon}`}/>
                  </IconButton>
                </DropdownToggle>

                <DropdownMenu right className="w-50">
                  <LanguageSwitcher switchLanguage={onSwitchLanguage}
                                    handleRequestClose={handleRequestClose}/>
                </DropdownMenu>
              </Dropdown>
            </li>
            */}

          {/*
          <li className="list-inline-item app-tour">
            <Dropdown
              className="quick-menu"
              isOpen={appNotification}
              toggle={onAppNotificationSelect}
            >
              <DropdownToggle
                className="d-inline-block"
                tag="span"
                data-toggle="dropdown"
              >
                <IconButton className="icon-btn">
                  <i className="zmdi zmdi-notifications-none icon-alert animated infinite wobble" />
                </IconButton>
              </DropdownToggle>

              <DropdownMenu right>
                <CardHeader
                  styleName="align-items-center"
                  heading="Notificação"
                />
                <AppNotification />
              </DropdownMenu>
            </Dropdown>
          </li>
          <li className="list-inline-item mail-tour">
            <Dropdown
              className="quick-menu"
              isOpen={mailNotification}
              toggle={onMailNotificationSelect}
            >
              <DropdownToggle
                className="d-inline-block"
                tag="span"
                data-toggle="dropdown"
              >
                <IconButton className="icon-btn">
                  <MessageIcon style={{ color: '#fff' }} />
                </IconButton>
              </DropdownToggle>

              <DropdownMenu right>
                <CardHeader
                  styleName="align-items-center"
                  heading="Notificação"
                />
                <MailNotification />
              </DropdownMenu>
            </Dropdown>
          </li>
          */}

          <li className="list-inline-item mail-tour">
            <IconButton
              className="icon-btn"
              onClick={() => {
                dispatch(userSignOut());
              }}
            >
              <ExitToApp style={{ color: '#fff' }} />
            </IconButton>
          </li>
        </ul>
        <div className="ellipse-shape" />
      </Toolbar>
    </AppBar>
  );
};

export default withRouter(Index);

/*
const comparaProps = function (prevProps, nextProps) {
  return prevProps.connection === nextProps.connection;
};

export default React.memo(Index, comparaProps);
*/
