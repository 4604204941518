import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Alert from '@material-ui/lab/Alert';

import {
  makeStyles,
  TextField,
  Button,
  Grid,
  InputAdornment,
  IconButton,
} from '@material-ui/core';

import {
  AccountCircle,
  LockRounded,
  AccountBalance,
  Visibility,
  VisibilityOff,
} from '@material-ui/icons';

import {
  showAuthLoader,
  userSignIn,
  showAuthMessage,
  hideMessage,
} from 'actions/Auth';

import publicIp from 'public-ip';
import api from 'services/api2';

import imgFundo from '../assets/images/imagem6.jpg';
import logo from '../assets/images/logo-cobcloud.png';
import logoSSL from '../assets/images/siteseal-positive-ssl.png';

const useStyles = makeStyles({
  inputUpper: {
    textTransform: 'uppercase',
  },
});

const SignIn = (props) => {
  const classes = useStyles();

  const [mostraConta, setMostraConta] = useState(true);
  const [conta, setConta] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [ip, setIp] = useState('');

  const [imagemFundo, setImagemFundo] = useState('');
  const [imagemLogo, setImagemLogo] = useState('');

  const [showPasswords, setShowPasswords] = useState({
    showPassword: false,
  });

  const dispatch = useDispatch();

  const { loader, alertMessage, showMessage, authUser } = useSelector(
    ({ auth }) => auth
  );

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 3000);
    }

    if (authUser !== null) {
      props.history.push('/');
    }
  }, [showMessage, authUser, props.history, dispatch]);

  useEffect(() => {
    const vhost_orig = window.location.hostname;
    let vhost = window.location.hostname;
    vhost = vhost.replace('localhost', '');
    vhost = vhost.replace('.com.br', '');
    vhost = vhost.replace('.cobcloud', '');
    vhost = vhost.replace('app.cob.cloud', '');
    vhost = vhost.replace('.cob.cloud', '');
    vhost = vhost.replace('-novo', '');
    vhost = vhost.replace('-', '');
    vhost = vhost.replace('.', '');
    vhost = vhost.toUpperCase();

    if (
      vhost_orig == 'app.cobcloud.com.br' ||
      vhost_orig == 'app.cob.cloud' ||
      vhost_orig == 'sis5.cobcloud.com.br' ||
      vhost_orig == 'hom.cobcloud.com.br' ||
      vhost_orig == 'localhost'
    )
      setMostraConta(false);
    else setMostraConta(true);

    setConta(vhost);

    dadosEmpresa(vhost);

    getIPPublico();
  }, [props.history]);

  async function dadosEmpresa(conta) {
    if (!conta) {
      setImagemFundo(imgFundo);
      setImagemLogo(logo);

      return;
    }

    try {
      const response = await api.get(`/dados_empresa/${conta}`);
      const data = await response.data;

      if (data.length > 0) {
        if (data[0].fundo) setImagemFundo(data[0].fundo);
        else setImagemFundo(imgFundo);

        if (data[0].logo) setImagemLogo(data[0].logo);
        else setImagemLogo(logo);
      } else {
        setImagemFundo(imgFundo);
        setImagemLogo(logo);
      }
    } catch {
      setImagemFundo(imgFundo);
      setImagemLogo(logo);
    }
  }

  async function getIPPublico() {
    try {
      const meuIPPublico = await publicIp.v4();
      setIp(meuIPPublico);
    } catch (error) {
      // console.log('....', error);
    }
  }

  function login() {
    if (conta && username && password) {
      dispatch(showAuthLoader());
      setTimeout(() => {
        dispatch(userSignIn({ conta, username, password, ip }));
      }, 2000);
    } else {
      dispatch(
        showAuthMessage('Todos os campos de preenchimento obrigatório!')
      );
    }
  }

  const handleClickShowPassword = (text) => {
    setShowPasswords({
      ...showPasswords,
      showPassword: !showPasswords.showPassword,
    });
  };

  return (
    <div>
      <Grid container style={{ height: '100vh', overflowY: 'auto' }}>
        <Grid item xs={12} sm={8}>
          <img
            src={imagemFundo}
            alt="bussines"
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
        </Grid>
        <Grid
          container
          item
          xs={12}
          sm={4}
          alignItems="center"
          direction="column"
          justifyContent="center"
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '0',
              margin: '0',
              minWidth: '80%',
              maxWidth: '90%',
            }}
          >
            <Grid container justifyContent="center">
              <img
                src={imagemLogo}
                alt="logo"
                style={{ width: '70%', height: 'auto' }}
              />
            </Grid>
            <div style={{ height: 20 }} />
            <TextField
              hidden={mostraConta}
              disabled={loader}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                maxLength: 100,
                className: classes.inputUpper,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountBalance />
                  </InputAdornment>
                ),
              }}
              label="Conta"
              onChange={(e) => setConta(e.target.value)}
              value={conta}
              margin="normal"
            />
            <TextField
              disabled={loader}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                maxLength: 100,
                className: classes.inputUpper,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle />
                  </InputAdornment>
                ),
              }}
              label="Usuário"
              onChange={(e) => setUsername(e.target.value)}
              value={username}
              margin="normal"
              onKeyPress={(ev) => {
                if (ev.key == 'Enter') {
                  login();
                }
              }}
            />
            <TextField
              disabled={loader}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                maxLength: 100,
                //className: classes.inputUpper,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockRounded />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end" className="mr-2">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                      className="p-1"
                    >
                      {showPasswords.showPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              type={showPasswords.showPassword ? 'text' : 'password'}
              label="Senha"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              margin="normal"
              onKeyPress={(ev) => {
                if (ev.key == 'Enter') {
                  login();
                }
              }}
            />
            <div style={{ height: 20 }} />
            <Button
              onClick={() => {
                login();
              }}
              disabled={loader}
              variant="contained"
              // color="primary"
              // style={{ width: '120px' }}
              className="jr-btn-sm bg-indigo text-white"
              // className="jr-btn-sm bg-success text-white"
            >
              {!loader ? (
                <div>
                  <span>Acessar</span>
                </div>
              ) : (
                <div>
                  <i className="zmdi zmdi-refresh zmdi-hc-fw zmdi-hc-spin mr-2" />
                  <span>Aguarde...</span>
                </div>
              )}
            </Button>
            <div style={{ height: 20 }} />
            <Alert hidden={!showMessage} severity="error">
              {alertMessage}
            </Alert>

            <div
              style={{
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'center',
                position: 'absolute',
                bottom: '10px',
                right: '20px',
              }}
            >
              <img src={logoSSL} alt="logossl" />
            </div>

            {/*
            <div style={{ height: 20 }} />            
            <Link
              className="text-warning"
              to="/"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: '#F2520B',
              }}
            >
              Esqueci minha senha
            </Link>
            */}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default SignIn;
