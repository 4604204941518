import {
  FILTROS_CREDOR,
  FILTROS_DEVEDOR,
  FILTROS_RECEBIMENTOS_LIBERADOS,
  RESET_REDUX_RECEBIMENTOS_LIBERADOS,
  FILTROS_ACIONAR,
  RESET_FILTER_REDUX,
  FILTROS_ACORDOS_PROGRAMADOS,
  RESET_FILTROS_ACORDOS_PROGRAMADOS,
  FILTROS_MINHA_AGENDA,
  RESET_FILTROS_MINHA_AGENDA,
} from 'constants/ActionTypes';

export function setReduxFiltrosCredor(payload) {
  return { type: FILTROS_CREDOR, payload };
}

export function setReduxFiltrosDevedor(payload) {
  return { type: FILTROS_DEVEDOR, payload };
}

export function setReduxFiltrosRecebimentosLiberados(payload) {
  return { type: FILTROS_RECEBIMENTOS_LIBERADOS, payload };
}

export function resetReduxRecebimentosLiberados(payload) {
  return { type: RESET_REDUX_RECEBIMENTOS_LIBERADOS, payload };
}

export function setReduxFiltrosAcionar(payload) {
  return { type: FILTROS_ACIONAR, payload };
}

export function resetFiltersRedux(payload = null) {
  return { type: RESET_FILTER_REDUX, payload };
}

export function setReduxFiltrosAcordosProgramados(payload) {
  return { type: FILTROS_ACORDOS_PROGRAMADOS, payload };
}

export function resetFiltersReduxAcordosProgramados(payload = null) {
  return { type: RESET_FILTROS_ACORDOS_PROGRAMADOS, payload };
}

export function setReduxFiltrosMinhaAgenda(payload) {
  return { type: FILTROS_MINHA_AGENDA, payload };
}

export function resetFiltersReduxMinhaAgenda(payload = null) {
  return { type: RESET_FILTROS_MINHA_AGENDA, payload };
}
