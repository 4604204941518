import {
  DADOS_PROCESSO,
  DADOS_DOCUMENTO,
  DADOS_DISCADOR,
  CONNECTION_WS,
} from 'constants/ActionTypes';

const INIT_STATE = {
  dadosProcesso: [],
  dadosDocumento: {},
  dadosDiscador: {
    vonix: null,
    ativo: false,
    logado: false,
    pausado: false,
    campanha_logada: '',
    campanhas: [],
    pausas: [],
    inicializado: false,
  },
  connectionWs: null,
};

const store = (state = INIT_STATE, action) => {
  switch (action.type) {
    case DADOS_PROCESSO: {
      return {
        ...state,
        dadosProcesso: action.dadosProcesso,
      };
    }
    case DADOS_DISCADOR: {
      return {
        ...state,
        dadosDiscador: action.dadosDiscador,
      };
    }

    case DADOS_DOCUMENTO: {
      return {
        ...state,
        dadosDocumento: action.dadosDocumento,
      };
    }

    case CONNECTION_WS: {
      return {
        ...state,
        connectionWs: action.connectionWs,
      };
    }

    default:
      return state;
  }
};

export default store;
