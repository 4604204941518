import React from 'react';
import CustomScrollbars from 'util/CustomScrollbars';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { setThemeColor } from 'actions/index';

import {
  DARK_THEME_COLOR,
  AMBER,
  BLUE,
  DEEP_ORANGE,
  GREEN,
  PINK,
  INDIGO,
  CYAN,
  DARK_CYAN,
  DARK_DEEP_ORANGE,
  DARK_AMBER,
  DARK_INDIGO,
  DARK_GREEN,
  DARK_PINK,
  DARK_BLUE,
} from 'constants/ThemeColors';
import { getControleDeAcesso } from 'util/AuthUtils';
import Navigation from '../../components/Navigation';

const SideBarContent = () => {
  const dispatch = useDispatch();
  const { themeColor } = useSelector(({ settings }) => settings);
  const authUserInfo = useSelector(({ auth }) => auth.authUserInfo);

  const handleThemeColor = (colorCode) => {
    dispatch(setThemeColor(colorCode));
    const body = document.body.classList;
    body.remove(themeColor);
    body.remove('dark-theme');
    body.add(colorCode);
  };

  let tipoMenu = 'cob';
  const location = useLocation();
  const pathFullMenu = location.pathname;
  const pathMenuPos = pathFullMenu.indexOf('/', 6);
  let pathFull = pathFullMenu;
  if (pathMenuPos > 0) {
    pathFull = pathFullMenu.substring(0, pathMenuPos);
  }

  const sideBarDark = localStorage.getItem('sidebar_dark') === 'true';

  if (pathFull == '/app/config') {
    tipoMenu = 'conf';
    if (sideBarDark && themeColor != DARK_DEEP_ORANGE)
      handleThemeColor(DARK_DEEP_ORANGE);
    else if (!sideBarDark && themeColor != DEEP_ORANGE)
      handleThemeColor(DEEP_ORANGE);
  } else if (pathFull == '/app/rel') {
    tipoMenu = 'rel';
    if (sideBarDark && themeColor != DARK_AMBER) handleThemeColor(DARK_AMBER);
    else if (!sideBarDark && themeColor != AMBER) handleThemeColor(AMBER);
  } else if (pathFull == '/app/cad') {
    tipoMenu = 'cad';
    if (sideBarDark && themeColor != DARK_CYAN) handleThemeColor(DARK_CYAN);
    else if (!sideBarDark && themeColor != CYAN) handleThemeColor(CYAN);
  } else if (pathFull == '/app/backof') {
    tipoMenu = 'backof';
    if (sideBarDark && themeColor != DARK_INDIGO) handleThemeColor(DARK_INDIGO);
    else if (!sideBarDark && themeColor != INDIGO) handleThemeColor(INDIGO);
  } else if (pathFull == '/app/fin') {
    tipoMenu = 'fin';
    if (sideBarDark && themeColor != DARK_GREEN) handleThemeColor(DARK_GREEN);
    else if (!sideBarDark && themeColor != GREEN) handleThemeColor(GREEN);
  } else if (pathFull == '/app/com') {
    tipoMenu = 'com';
    if (sideBarDark && themeColor != DARK_PINK) handleThemeColor(DARK_PINK);
    else if (!sideBarDark && themeColor != PINK) handleThemeColor(PINK);
  } else {
    tipoMenu = 'cob';
    if (sideBarDark && themeColor != DARK_BLUE) handleThemeColor(DARK_BLUE);
    else if (!sideBarDark && themeColor != BLUE) handleThemeColor(BLUE);
  }

  let navigationMenus = [];

  if (tipoMenu == 'rel') {
    // menu relatórios

    navigationMenus = [
      {
        name: 'Menu',
        type: 'section',
        children: [
          {
            name: 'Inicio',
            icon: 'fa fa-th-large fa-fw',
            iconSize: '1.2em',
            type: 'item',
            ativo: true,
            link: '/app/rel/inicio',
          },

          {
            name: '01. Processos',
            icon: 'fa fa-file-excel-o fa-fw',
            iconSize: '1.2em',
            type: 'collapse',
            ativo: getControleDeAcesso('rel_01', authUserInfo),
            children: [
              {
                name: '01.01 - Consulta Geral',
                type: 'item',
                ativo: true,
                link: '/app/rel/consulta-geral',
              },
              {
                name: '01.02 - Lista de Processos',
                type: 'item',
                ativo: true,
                link: '/app/rel/lista-de-processos',
              },
              {
                name: '01.04 - Status de Processo x Credor',
                type: 'item',
                ativo: true,
                link: '/app/rel/processos-x-credor',
              },
              {
                name: '01.05 - Status de Processo x Negociador',
                type: 'item',
                ativo: true,
                link: '/app/rel/processos-x-negociador',
              },
              {
                name: '01.06 - Qtde de Processos x Região',
                type: 'item',
                ativo: true,
                link: '/app/rel/processos-x-regiao',
              },
              {
                name: '01.07 - Processos s/ Títulos',
                type: 'item',
                ativo: true,
                link: '/app/rel/processos-sem-titulos',
              },
            ],
          },
          {
            name: '02. Históricos / Ocorrências',
            icon: 'fa fa-file-excel-o fa-fw',
            iconSize: '1.2em',
            type: 'collapse',
            ativo: getControleDeAcesso('rel_02', authUserInfo),
            children: [
              {
                name: '02.01 - Históricos / Ocorrências',
                type: 'item',
                ativo: true,
                link: '/app/rel/lista-de-ocorrencias/',
              },
            ],
          },
          {
            name: '03. Títulos',
            icon: 'fa fa-file-excel-o fa-fw',
            iconSize: '1.2em',
            type: 'collapse',
            ativo: getControleDeAcesso('rel_03', authUserInfo),
            children: [
              {
                name: '03.01 - Lista de Títulos',
                type: 'item',
                ativo: true,
                link: '/app/rel/lista-de-titulos',
              },
              {
                name: '03.02 - Lista de Importações',
                type: 'item',
                ativo: true,
                link: '/app/rel/lista-de-importacoes',
              },
              {
                name: '03.03 - Títulos Duplicados',
                type: 'item',
                ativo: true,
                link: '/app/rel/titulos-duplicados',
              },
            ],
          },
          {
            name: '05. Devedores',
            icon: 'fa fa-file-excel-o fa-fw',
            iconSize: '1.2em',
            type: 'collapse',
            ativo: getControleDeAcesso('rel_05', authUserInfo),
            children: [
              {
                name: '05.01 - Lista de Devedores',
                type: 'item',
                ativo: true,
                link: '/app/rel/lista-de-devedores',
              },
            ],
          },
          {
            name: '06. Negociadores',
            icon: 'fa fa-file-excel-o fa-fw',
            iconSize: '1.2em',
            type: 'collapse',
            ativo: getControleDeAcesso('rel_06', authUserInfo),
            children: [
              {
                name: '06.01 - Produtividade',
                type: 'item',
                ativo: true,
                link: '/app/rel/produtividade',
              },
              {
                name: '06.02 - Pausas',
                type: 'item',
                ativo: true,
                link: '/app/rel/pausas',
              },
            ],
          },
          {
            name: '07. Consultor / Comercial',
            icon: 'fa fa-file-excel-o fa-fw',
            iconSize: '1.2em',
            type: 'collapse',
            ativo: getControleDeAcesso('rel_07', authUserInfo),
            children: [
              {
                name: '07.01 - Títulos Captados - Mensal',
                type: 'item',
                ativo: true,
                link: '/app/rel/titulos-captados-mensal',
              },
              {
                name: '07.xx - Movimentação Anual de Clientes',
                type: 'item',
                ativo: false,
                link: '/app/rel/mov-anual-clientes',
              },
              {
                name: '07.02 - Títulos Captados - Faixa Vencto',
                type: 'item',
                ativo: true,
                link: '/app/rel/titulos-captados-faixa',
              },
              {
                name: '07.03 - Credores Que Não Enviam Borderôs X Dias',
                type: 'item',
                ativo: true,
                link: '/app/rel/credores-nao-enviam-borderos-x-dias',
              },
            ],
          },
          {
            name: '08. Financeiro',
            icon: 'fa fa-file-excel-o fa-fw',
            iconSize: '1.2em',
            type: 'collapse',
            ativo: getControleDeAcesso('rel_08', authUserInfo),
            children: [
              {
                name: '08.01 - Contas à Receber / Recebidas',
                type: 'item',
                ativo: true,
                link: '/app/rel/contas-a-receber',
              },
              {
                name: '08.02 - Contas à Pagar / Pagas',
                type: 'item',
                ativo: true,
                link: '/app/rel/contas-a-pagar',
              },
              {
                name: '08.03 - Movimetações de Contas',
                type: 'item',
                ativo: true,
                link: '/app/rel/mov-contas',
              },
            ],
          },

          {
            name: '09. Gerencial',
            icon: 'fa fa-file-excel-o fa-fw',
            iconSize: '1.2em',
            type: 'collapse',
            ativo: getControleDeAcesso('rel_09', authUserInfo),
            children: [
              {
                name: '09.02 - Remuneração por Credor',
                type: 'item',
                ativo: true,
                link: '/app/rel/lista-remuneracao-credor',
              },
              {
                name: '09.03 - Remuneração por Negociador',
                type: 'item',
                ativo: true,
                link: '/app/rel/lista-remuneracao-negociador',
              },
            ],
          },

          {
            name: '10. Remessa / Retorno',
            icon: 'fa fa-file-text-o fa-fw',
            iconSize: '1.2em',
            type: 'collapse',
            ativo: getControleDeAcesso('rel_remessa', authUserInfo),
            children: [
              {
                name: 'Entrada Confirmada',
                type: 'item',
                ativo: true,
                link: '/app/rel/entrada-confirmada',
              },
              {
                name: 'Liquidações',
                type: 'item',
                ativo: true,
                link: '/app/rel/liquidacoes',
              },
              {
                name: 'Devoluções',
                type: 'item',
                ativo: true,
                link: '/app/rel/devolucoes',
              },
              {
                name: 'Liquidações/Devoluções',
                type: 'item',
                ativo: false,
                // link: '',
              },
              {
                name: 'Ocorrências',
                type: 'item',
                ativo: true,
                link: '/app/rel/ocorrencias',
              },
            ],
          },

          {
            name: 'Relatórios Personalizados',
            icon: 'fa fa-file-text-o fa-fw',
            iconSize: '1.2em',
            type: 'item',
            ativo: getControleDeAcesso('rel_personalizados', authUserInfo),
            link: '/app/rel/personalizados',
          },
          {
            name: 'Relatórios em PDF',
            icon: 'fa fa-file-text-o fa-fw',
            iconSize: '1.2em',
            type: 'item',
            ativo: getControleDeAcesso('rel_pdf', authUserInfo),
            link: '/app/rel/pdf',
          },

          {
            name: 'Criação de Documentos',
            icon: 'fa fa-bolt fa-fw',
            iconSize: '1.2em',
            type: 'item',
            ativo: getControleDeAcesso('rel_gerador', authUserInfo),
            link: '/app/rel/criacao-documentos',
          },
        ],
      },
    ];
  } else if (tipoMenu == 'cad') {
    // menu cadastros

    navigationMenus = [
      {
        name: 'Menu',
        type: 'section',
        children: [
          {
            name: 'Inicio',
            icon: 'fa fa-th-large fa-fw',
            iconSize: '1.2em',
            type: 'item',
            ativo: true,
            link: '/app/cad/inicio',
          },

          {
            name: 'Carteiras / Credores',
            icon: 'fa fa-university fa-fw',
            iconSize: '1.2em',
            type: 'item',
            ativo: getControleDeAcesso('cad_credor_v', authUserInfo),
            link: '/app/cad/credores',
          },

          {
            name: 'Devedores',
            icon: 'fa fa-user fa-fw',
            iconSize: '1.2em',
            type: 'item',
            ativo: getControleDeAcesso('cad_devedor_v', authUserInfo),
            link: '/app/cad/devedores',
          },

          {
            name: 'Grupos de Credores',
            icon: 'fa fa-users fa-fw',
            iconSize: '1.2em',
            type: 'item',
            ativo: getControleDeAcesso('cad_credor_grupo', authUserInfo),
            link: '/app/cad/grupo-credores',
          },
          {
            name: 'Colaboradores',
            icon: 'fa fa-user-circle-o fa-fw',
            iconSize: '1.2em',
            type: 'item',
            ativo: getControleDeAcesso('cad_colaborador_v', authUserInfo),
            link: '/app/cad/colaboradores',
          },
          {
            name: 'Equipes',
            icon: 'fa fa-users fa-fw',
            iconSize: '1.2em',
            type: 'item',
            ativo: getControleDeAcesso('cad_colaborador_grupo', authUserInfo),
            link: '/app/cad/equipes',
          },
          {
            name: 'Fornecedores',
            icon: 'fa fa-truck fa-fw',
            iconSize: '1.2em',
            type: 'item',
            ativo: getControleDeAcesso('cad_fornecedor_v', authUserInfo),
            link: '/app/cad/fornecedores',
          },

          /*
          {
            name: 'Digitalizações',
            icon: 'zmdi zmdi-hc-fw zmdi-file',
            type: 'collapse',
            children: [
              {
                name: 'Diretórios',
                type: 'item',
                ativo: true,
                link: '/app/cad/diretorios',
                bloq: true,
              },
              {
                name: 'Documentos',
                type: 'item',
                ativo: true,
                link: '/app/cad/documentos',
                bloq: true,
              },
            ],
          },
          */

          {
            name: 'Tabelas - Cadastro',
            ativo:
              getControleDeAcesso('tab_categoria_cred', authUserInfo) ||
              getControleDeAcesso('tab_segmento_cred', authUserInfo) ||
              getControleDeAcesso('tab_segmento_dev', authUserInfo) ||
              getControleDeAcesso('tab_tags_marcacoes', authUserInfo) ||
              getControleDeAcesso('tab_especie_titulo', authUserInfo),
            icon: 'fa fa-folder-open fa-fw',
            iconSize: '1.2em',
            type: 'collapse',
            children: [
              {
                name: 'Categorias Credores',
                type: 'item',
                ativo:
                  getControleDeAcesso('tab_categoria_cred', authUserInfo) ==
                  true,
                link: '/app/cad/tabelas/categorias-credor',
              },
              {
                name: 'Segmentos Credores',
                type: 'item',
                ativo:
                  getControleDeAcesso('tab_segmento_cred', authUserInfo) ==
                  true,
                link: '/app/cad/tabelas/segmento-credor',
              },
              {
                name: 'Segmentos Devedores',
                type: 'item',
                ativo:
                  getControleDeAcesso('tab_segmento_dev', authUserInfo) == true,
                link: '/app/cad/tabelas/segmento-devedor',
              },
              {
                name: 'Marcações / Tags de Processos',
                type: 'item',
                ativo:
                  getControleDeAcesso('tab_tags_marcacoes', authUserInfo) ==
                  true,
                link: '/app/cad/tabelas/marcacoes-de-processo',
              },
              {
                name: 'Espécie de Título',
                type: 'item',
                ativo:
                  getControleDeAcesso('tab_especie_titulo', authUserInfo) ==
                  true,
                link: '/app/cad/tabelas/especie-titulo',
              },
            ],
          },

          {
            name: 'Tabelas - Financeiro',
            ativo:
              getControleDeAcesso('tab_bancos', authUserInfo) ||
              getControleDeAcesso('tab_contas', authUserInfo) ||
              getControleDeAcesso('tab_custo', authUserInfo) ||
              getControleDeAcesso('tab_plano_conta', authUserInfo) ||
              getControleDeAcesso('tab_forma_pagto', authUserInfo) ||
              getControleDeAcesso('tab_empresas', authUserInfo),
            icon: 'fa fa-folder-open fa-fw',
            iconSize: '1.2em',
            type: 'collapse',
            children: [
              {
                name: 'Bancos',
                type: 'item',
                ativo: getControleDeAcesso('tab_bancos', authUserInfo) == true,
                link: '/app/cad/tabelas/bancos',
              },
              {
                name: 'Contas Bancárias',
                type: 'item',
                ativo: getControleDeAcesso('tab_contas', authUserInfo) == true,
                link: '/app/cad/tabelas/contas-bancarias',
              },
              {
                name: 'Centro de Custo',
                type: 'item',
                ativo: getControleDeAcesso('tab_custo', authUserInfo) == true,
                link: '/app/cad/tabelas/centro-custo',
              },
              {
                name: 'Plano de Conta',
                type: 'item',
                ativo:
                  getControleDeAcesso('tab_plano_conta', authUserInfo) == true,
                link: '/app/cad/tabelas/plano-conta',
              },
              {
                name: 'Formas de Pagamento',
                type: 'item',
                ativo:
                  getControleDeAcesso('tab_forma_pagto', authUserInfo) == true,
                link: '/app/cad/tabelas/forma-pagto',
              },
              {
                name: 'Empresas',
                type: 'item',
                ativo:
                  getControleDeAcesso('tab_empresas', authUserInfo) == true,
                link: '/app/cad/tabelas/empresa',
              },
            ],
          },

          {
            name: 'Tabelas - Parametrizações',
            ativo:
              getControleDeAcesso('tab_param_cob', authUserInfo) == true ||
              getControleDeAcesso('tab_param_car', authUserInfo) == true ||
              getControleDeAcesso('tab_camp_desc', authUserInfo) == true ||
              getControleDeAcesso('tab_modal_contr', authUserInfo) == true ||
              getControleDeAcesso('tab_comissao_escalonada', authUserInfo) ==
                true ||
              getControleDeAcesso('tab_modelos_pc', authUserInfo) == true,
            icon: 'fa fa-folder-open fa-fw',
            iconSize: '1.2em',
            type: 'collapse',
            children: [
              {
                name: 'Parâmetros de Cobrança',
                type: 'item',
                ativo: getControleDeAcesso('tab_param_cob', authUserInfo),
                link: '/app/cad/tabelas/parametros-atraso',
              },
              {
                name: 'Parametrizações de Carteira',
                type: 'item',
                ativo: getControleDeAcesso('tab_param_car', authUserInfo),
                link: '/app/cad/tabelas/parametros-carteira',
              },
              {
                name: 'Campanhas de Desconto',
                type: 'item',
                ativo: getControleDeAcesso('tab_camp_desc', authUserInfo),
                link: '/app/cad/tabelas/campanhas-desconto',
              },
              {
                name: 'Modalidades de Contrato',
                type: 'item',
                ativo: getControleDeAcesso('tab_modal_contr', authUserInfo),
                link: '/app/cad/tabelas/modalidade-contrato',
              },
              {
                name: 'Comissão Escalonada',
                type: 'item',
                ativo: getControleDeAcesso(
                  'tab_comissao_escalonada',
                  authUserInfo
                ),
                link: '/app/cad/tabelas/comissao-escalonada',
              },
              {
                name: 'Modelos de Prestação',
                type: 'item',
                ativo: getControleDeAcesso('tab_modelos_pc', authUserInfo),
                link: '/app/cad/tabelas/modelos-prestacao',
              },
            ],
          },

          {
            name: 'Tabelas - Discador',
            ativo: getControleDeAcesso('tabelas_discador', authUserInfo),
            icon: 'fa fa-folder-open fa-fw',
            iconSize: '1.2em',
            type: 'collapse',
            children: [
              {
                name: 'Qualificação de Telefones',
                type: 'item',
                ativo:
                  getControleDeAcesso('tabelas_discador', authUserInfo) == true,
                link: '/app/cad/tabelas/qualificacao-telefones',
              },
              {
                name: 'Qualificação de Chamadas (Manual)',
                type: 'item',
                ativo:
                  getControleDeAcesso('tabelas_discador', authUserInfo) == true,
                link: '/app/cad/tabelas/qualificacao-chamadas',
              },
              {
                name: 'Qualificação de Chamadas (Retorno Discador Automático)',
                type: 'item',
                ativo:
                  getControleDeAcesso('tabelas_discador', authUserInfo) == true,
                link: '/app/cad/tabelas/qualificacao-chamadas-discador',
              },
              {
                name: 'Motivos de Pausa',
                type: 'item',
                ativo:
                  getControleDeAcesso('tabelas_discador', authUserInfo) == true,
                link: '/app/cad/tabelas/motivo-pausa',
              },
            ],
          },

          {
            name: 'Tabelas - Diversos',
            ativo:
              getControleDeAcesso('tab_fases', authUserInfo) ||
              getControleDeAcesso('tab_classifica_acordo', authUserInfo) ||
              getControleDeAcesso('tab_inclusao_negativacao', authUserInfo) ||
              getControleDeAcesso('tab_retirada_negativacao', authUserInfo),
            icon: 'fa fa-folder-open fa-fw',
            iconSize: '1.2em',
            type: 'collapse',
            children: [
              /*
              {
                name: 'Períodos de Comissão',
                 type: 'item', ativo: true,
                link: '/app/cad/tabelas/periodo-comissao',
              },
              */
              {
                name: 'Fases de Processo',
                type: 'item',
                ativo: getControleDeAcesso('tab_fases', authUserInfo) == true,
                link: '/app/cad/tabelas/fases-processo',
              },
              {
                name: 'Classificação de Acordos',
                type: 'item',
                ativo:
                  getControleDeAcesso('tab_classifica_acordo', authUserInfo) ==
                  true,
                link: '/app/cad/tabelas/classificacao-acordos',
              },
              {
                name: 'Negativação - Naturezas de Inclusão',
                type: 'item',
                ativo:
                  getControleDeAcesso(
                    'tab_inclusao_negativacao',
                    authUserInfo
                  ) == true,
                link: '/app/cad/tabelas/negativacao-natureza-inclusao',
              },
              {
                name: 'Negativação - Motivos de Retirada',
                type: 'item',
                ativo:
                  getControleDeAcesso(
                    'tab_retirada_negativacao',
                    authUserInfo
                  ) == true,
                link: '/app/cad/tabelas/negativacao-motivos-retirada',
              },
            ],
          },
        ],
      },
    ];
  } else if (tipoMenu == 'fin') {
    navigationMenus = [
      {
        name: 'Menu',
        type: 'section',
        children: [
          {
            name: 'Inicio',
            icon: 'fa fa-th-large fa-fw',
            iconSize: '1.2em',
            type: 'item',
            ativo: true,
            link: '/app/fin/inicio',
          },
          {
            name: 'Recebimentos',
            icon: 'fa fa-usd fa-fw',
            iconSize: '1.2em',
            type: 'collapse',
            ativo: !(
              getControleDeAcesso('fin_rec_vis', authUserInfo) == false &&
              getControleDeAcesso('fin_rec_cad', authUserInfo) == false &&
              getControleDeAcesso('fin_rec_ident', authUserInfo) == false
            ),
            children: [
              {
                name: 'Liberados',
                type: 'item',
                ativo: getControleDeAcesso('fin_rec_vis', authUserInfo),
                link: '/app/fin/recebimentos/liberados-baixa',
              },
              {
                name: 'Bloqueados / a Identificar',
                type: 'item',
                ativo: getControleDeAcesso('fin_rec_bloq_vis', authUserInfo),
                link: '/app/fin/recebimentos/bloqueados-identificar',
              },
              {
                name: 'Importar',
                type: 'item',
                ativo: getControleDeAcesso('fin_rec_cad', authUserInfo),
                link: '/app/fin/recebimentos/importacoes',
                bloq: false,
              },
              {
                name: 'Importar e Baixar',
                type: 'item',
                ativo: true,
                // ativo: getControleDeAcesso('fin_rec_cad', authUserInfo),
                link: '/app/fin/importar-recebimentos-baixas',
              },
            ],
          },
          {
            name: 'Baixas Realizadas',
            icon: 'fa fa-check-square-o fa-fw',
            iconSize: '1.2em',
            type: 'item',
            ativo: getControleDeAcesso('fin_baixas_vis', authUserInfo),
            link: '/app/fin/baixas-realizadas',
          },
          {
            name: 'Prestação de Contas',
            icon: 'fa fa-usd fa-fw',
            iconSize: '1.2em',
            type: 'item',
            ativo: getControleDeAcesso('fin_pc_vis', authUserInfo),
            link: '/app/fin/prestacao-contas',
          },
          {
            name: 'Repasses',
            icon: 'fa fa-usd fa-fw',
            iconSize: '1.2em',
            type: 'item',
            ativo: getControleDeAcesso('fin_rep_vis', authUserInfo),
            link: '/app/fin/repasses',
          },
          {
            name: 'Faturamento / NFSe',
            icon: 'fa fa-heartbeat fa-fw',
            iconSize: '1.2em',
            type: 'item',
            ativo: getControleDeAcesso('fin_pc_vis', authUserInfo),
            link: '/app/fin/nota-fiscal',
          },
          {
            name: 'Faturas',
            icon: 'zmdi zmdi-hc-fw zmdi-receipt',
            iconSize: '1.2em',
            type: 'item',
            ativo: getControleDeAcesso('fin_fat_vis', authUserInfo),
            link: '/app/fin/faturas',
          },
          {
            name: 'Boletos Bancários',
            icon: 'fa fa-barcode fa-fw',
            iconSize: '1.2em',
            type: 'item',
            ativo: getControleDeAcesso('fin_bol_vis', authUserInfo),
            link: '/app/fin/boletos',
          },
          {
            name: 'Pix QRCode',
            icon: 'fa fa-qrcode fa-fw',
            iconSize: '1.2em',
            type: 'item',
            ativo: getControleDeAcesso('fin_pix_vis', authUserInfo),
            link: '/app/fin/pix',
          },
          {
            name: 'Cheques Recebidos',
            icon: 'fa fa-keyboard-o fa-fw',
            iconSize: '1.2em',
            type: 'item',
            ativo: getControleDeAcesso('fin_cheq_vis', authUserInfo),
            link: '/app/fin/cheques',
          },
          {
            name: 'Gerencial',
            icon: 'fa fa-line-chart fa-fw',
            iconSize: '1.2em',
            type: 'collapse',
            ativo:
              getControleDeAcesso('fin_crec_vis', authUserInfo) ||
              getControleDeAcesso('fin_cpag_vis', authUserInfo) ||
              getControleDeAcesso('fin_movc_vis', authUserInfo),
            children: [
              {
                name: 'Contas a Receber',
                icon: 'fa fa-plus-square-o',
                iconSize: '1.2em',
                type: 'item',
                ativo: getControleDeAcesso('fin_crec_vis', authUserInfo),
                link: '/app/fin/contas-a-receber',
              },
              {
                name: 'Contas a Pagar',
                icon: 'fa fa-minus-square-o',
                iconSize: '1.2em',
                type: 'item',
                ativo: getControleDeAcesso('fin_cpag_vis', authUserInfo),
                link: '/app/fin/contas-a-pagar',
              },
              {
                name: 'Movimentações',
                icon: 'fa fa-money',
                iconSize: '1.2em',
                type: 'item',
                ativo: getControleDeAcesso('fin_movc_vis', authUserInfo),
                link: '/app/fin/mov-contas',
              },
            ],
          },
          {
            name: 'Tá Pago - Alega Pagto',
            icon: 'zmdi zmdi-hc-fw zmdi-desktop-windows',
            iconSize: '1.2em',
            type: 'item',
            ativo: getControleDeAcesso('fin_alega_pag', authUserInfo),
            link: '/app/fin/portal-alega-pagamento',
          },
          {
            name: 'Instruções ao Financeiro',
            icon: 'fa fa-bell-o fa-fw',
            iconSize: '1.2em',
            type: 'item',
            ativo: true,
            link: '/app/fin/instrucoes-negociadores',
          },
        ],
      },
    ];
  } else if (tipoMenu == 'backof') {
    // menu backoffice
    navigationMenus = [
      {
        name: 'Menu',
        type: 'section',
        children: [
          {
            name: 'Inicio',
            icon: 'fa fa-th-large fa-fw',
            iconSize: '1.2em',
            type: 'item',
            ativo: true,
            link: '/app/backof/inicio',
          },

          {
            name: 'Control Desk',
            icon: 'fa fa-table fa-fw',
            iconSize: '1.2em',
            type: 'item',
            ativo: getControleDeAcesso('back_controldesk', authUserInfo),
            link: '/app/backof/controldesk',
          },

          {
            name: 'Régua de Cobrança 🔥',
            icon: 'fa fa-registered fa-fw',
            type: 'item',
            ativo: false, //getControleDeAcesso('back_automacoes', authUserInfo),
            link: '/app/backof/regua-de-cobranca',
          },

          {
            name: 'Operacional',
            icon: 'fa fa-cogs fa-fw',
            iconSize: '1.2em',
            type: 'collapse',
            ativo:
              getControleDeAcesso('back_fila_acionamento', authUserInfo) ||
              getControleDeAcesso('back_liberacao_acordo', authUserInfo) ||
              getControleDeAcesso('back_titulos_a_liberar', authUserInfo) ||
              getControleDeAcesso('back_negativacao', authUserInfo) ||
              getControleDeAcesso('back_documentos_digitais', authUserInfo),
            children: [
              {
                name: 'Fila de Acionamento',
                type: 'item',
                ativo:
                  getControleDeAcesso('back_fila_acionamento', authUserInfo) ==
                  true,
                link: '/app/backof/operacional/fila-acionamento',
              },
              {
                name: 'Liberação de Acordos',
                type: 'item',
                ativo:
                  getControleDeAcesso('back_liberacao_acordo', authUserInfo) ==
                  true,
                link: '/app/backof/operacional/acordos-liberados',
              },
              {
                name: 'Títulos a serem Liberados',
                type: 'item',
                ativo:
                  getControleDeAcesso('back_titulos_a_liberar', authUserInfo) ==
                  true,
                link: '/app/backof/operacional/titulos-serem-liberados',
              },
              {
                name: 'Negativação',
                type: 'item',
                ativo:
                  getControleDeAcesso('back_negativacao', authUserInfo) == true,
                link: '/app/backof/operacional/negativacao',
              },
              {
                name: 'Documentos Digitais',
                type: 'item',
                ativo:
                  getControleDeAcesso(
                    'back_documentos_digitais',
                    authUserInfo
                  ) == true,
                link: '/app/backof/operacional/documentos-digitais',
              },
            ],
          },

          {
            name: 'Importações',
            icon: 'fa fa-user-plus fa-fw',
            iconSize: '1.2em',
            ativo:
              getControleDeAcesso('back_importacoes_tit', authUserInfo) ||
              getControleDeAcesso('back_higienizacao_dev', authUserInfo) ||
              getControleDeAcesso('back_manutencao_tit', authUserInfo) ||
              getControleDeAcesso('back_importacao_oco', authUserInfo),
            type: 'collapse',
            children: [
              {
                name: 'Importação de Títulos',
                type: 'item',
                ativo:
                  getControleDeAcesso('back_importacoes_tit', authUserInfo) ==
                  true,
                link: '/app/backof/importacoes/titulos',
              },
              {
                name: 'Importação de Títulos x Baixa de Carteira',
                type: 'item',
                ativo:
                  getControleDeAcesso('back_importacoes_tit', authUserInfo) ==
                  true,
                link: '/app/backof/importacoes/titulos-baixa',
              },
              {
                name: 'Higienização de Devedores',
                type: 'item',
                ativo:
                  getControleDeAcesso('back_higienizacao_dev', authUserInfo) ==
                  true,
                link: '/app/backof/higienizacao-devedores',
              },
              {
                name: 'Manutenção de Importações',
                type: 'item',
                ativo:
                  getControleDeAcesso('back_manutencao_tit', authUserInfo) ==
                  true,
                link: '/app/backof/importacoes/titulos-manutencao',
              },
              {
                name: 'Lotes de Títulos Enviados pelo Credor',
                type: 'item',
                ativo:
                  getControleDeAcesso('back_importacoes_tit', authUserInfo) ==
                  true,
                link: '/app/backof/importacoes/lotes-credor',
              },
              {
                name: 'Importação de Ocorrências',
                type: 'item',
                ativo:
                  getControleDeAcesso('back_importacao_oco', authUserInfo) ==
                  true,
                link: '/app/backof/importacoes/ocorrencias',
              },
            ],
          },

          {
            name: 'Devoluções / Retiradas',
            icon: 'fa fa-user-times fa-fw',
            iconSize: '1.2em',
            ativo: getControleDeAcesso('back_devolucao', authUserInfo),
            type: 'collapse',
            children: [
              {
                name: 'Devolução de Processo',
                type: 'item',
                ativo: getControleDeAcesso('back_devolucao', authUserInfo),
                link: '/app/backof/devolucao/devolucao-titulos',
              },
              {
                name: 'Devolução por Prazo',
                type: 'item',
                ativo: getControleDeAcesso('back_devolucao', authUserInfo),
                link: '/app/backof/devolucao/devolucao-por-prazo',
              },
            ],
          },

          {
            name: 'Ações Eletrônicas',
            icon: 'fa fa-envelope fa-fw',
            iconSize: '1.2em',
            ativo: !(
              getControleDeAcesso('back_acoes_email', authUserInfo) == false &&
              getControleDeAcesso('back_acoes_sms', authUserInfo) == false &&
              getControleDeAcesso('back_acoes_boletos', authUserInfo) == false
            ),
            type: 'collapse',
            children: [
              {
                name: 'Campanha de E-mail',
                type: 'item',
                ativo: getControleDeAcesso('back_acoes_email', authUserInfo),
                link: '/app/backof/acoeseletronicas/campanha-email',
              },
              {
                name: 'Campanha de SMS',
                type: 'item',
                ativo: getControleDeAcesso('back_acoes_sms', authUserInfo),
                link: '/app/backof/acoeseletronicas/campanha-sms',
              },
              {
                name: 'Emissão de Faturas / Boletos em Lote',
                type: 'item',
                ativo: getControleDeAcesso('back_acoes_boletos', authUserInfo),
                link: '/app/backof/acoeseletronicas/campanha-boleto',
              },
              {
                name: 'Modelos de E-mail',
                type: 'item',
                ativo: getControleDeAcesso('back_acoes_email', authUserInfo),
                link: '/app/backof/acoeseletronicas/modelo-email',
              },
              {
                name: 'Modelos de SMS/Whats',
                type: 'item',
                ativo: getControleDeAcesso('back_acoes_sms', authUserInfo),
                link: '/app/backof/acoeseletronicas/modelo-sms',
              },
            ],
          },

          {
            name: 'Discador',
            icon: 'zmdi zmdi-hc-fw zmdi-phone',
            ativo: getControleDeAcesso('back_discador', authUserInfo),
            type: 'collapse',
            children: [
              {
                name: 'Mailling',
                type: 'item',
                ativo: true,
                link: '/app/backof/discador/mailling',
              },
              {
                name: 'Automação de URA',
                type: 'item',
                ativo: true,
                link: '/app/backof/discador/mailling-auto',
              },
              {
                name: 'Importar / Retorno',
                type: 'item',
                ativo: false,
                link: '/app/xxx',
                bloq: true,
              },
            ],
          },

          {
            name: 'Portal do Credor',
            icon: 'zmdi zmdi-hc-fw zmdi-desktop-windows',
            type: 'collapse',
            ativo: true,
            children: [
              {
                name: 'Acessos',
                type: 'item',
                ativo: true,
                bloq: true,
                link: '/app/backof/portal-credor/acessos',
              },
              {
                name: 'Customização',
                type: 'item',
                ativo: true,
                link: '/app/backof/portal-credor/customizacao',
              },
            ],
          },

          {
            name: 'Portal de Negociações',
            icon: 'zmdi zmdi-hc-fw zmdi-desktop-windows',
            type: 'collapse',
            ativo: getControleDeAcesso('back_portal_neg', authUserInfo),
            children: [
              {
                name: 'Parametrizações',
                type: 'item',
                ativo: true,
                link: '/app/backof/portal/config',
              },
              {
                name: 'Acessos',
                type: 'item',
                ativo: true,
                link: '/app/backof/portal/acessos',
              },
              {
                name: 'Customização',
                type: 'item',
                ativo: true,
                link: '/app/backof/portal/customizacao',
              },
            ],
          },

          {
            name: 'Agente Virtual',
            icon: 'fa fa-github-alt fa-fw',
            type: 'collapse',
            ativo: getControleDeAcesso('back_agente_virtual', authUserInfo),
            children: [
              {
                name: 'Dashboard - Agentes',
                type: 'item',
                ativo: true,
                link: '/app/backof/agente-virtual/painel',
              },
              {
                name: 'Meus Agentes',
                type: 'item',
                ativo: true,
                link: '/app/backof/agente-virtual/agentes',
              },
              {
                name: 'Parametrizações',
                type: 'item',
                ativo: true,
                link: '/app/backof/agente-virtual/config',
              },
              {
                name: 'Mailling',
                type: 'item',
                ativo: true,
                link: '/app/backof/agente-virtual/mailling',
              },
              {
                name: 'Históricos',
                type: 'item',
                ativo: false,
                bloq: true,
                link: '/app/backof/agente-virtual/historicos',
              },
            ],
          },

          {
            name: 'Metas',
            icon: 'fa fa-bar-chart fa-fw',
            type: 'collapse',
            ativo: getControleDeAcesso('metas', authUserInfo),
            children: [
              {
                name: 'Dashboard - Empresa',
                type: 'item',
                ativo: true,
                link: '/app/backof/metas-empresa',
              },
              {
                name: 'Dashboard - Individual',
                type: 'item',
                ativo: true,
                link: '/app/backof/metas-negociador',
              },
              {
                name: 'Configurações',
                type: 'item',
                ativo: true,
                link: '/app/backof/metas/config',
              },
            ],
          },

          {
            name: 'Históricos',
            icon: 'fa fa-check-square-o fa-fw',
            type: 'collapse',
            ativo: true,
            children: [
              {
                name: 'Acessos',
                type: 'item',
                ativo: getControleDeAcesso('back_hist_acessos', authUserInfo),
                link: '/app/backof/historico-de-acesso',
              },
              {
                name: 'Ações',
                type: 'item',
                ativo: getControleDeAcesso('back_hist_acessos', authUserInfo),
                link: '/app/backof/historico-de-acoes',
              },
              {
                name: 'Higienizações',
                type: 'item',
                ativo: true,
                link: '/app/backof/historico-higienizacao',
              },
              {
                name: 'SMSs Enviados',
                type: 'item',
                ativo: true,
                link: '/app/backof/sms-enviados',
              },
              {
                name: 'E-mails Enviados',
                type: 'item',
                ativo: true,
                link: '/app/backof/emails-enviados',
              },
            ],
          },

          {
            name: 'Automações',
            icon: 'fa fa-cogs fa-fw',
            iconSize: '1.2em',
            type: 'item',
            ativo: getControleDeAcesso('back_automacoes', authUserInfo),
            link: '/app/backof/automacoes',
          },

          {
            name: 'Ferramentas',
            icon: 'fa fa-wrench fa-fw',
            type: 'collapse',
            //** Usado este hash só para testar na base teste
            ativo:
              getControleDeAcesso(
                'back_baixas_titulos_planilha',
                authUserInfo
              ) ||
              getControleDeAcesso('back_baixas_titulos_lote', authUserInfo) ||
              getControleDeAcesso('back_migrar_processos', authUserInfo) ||
              getControleDeAcesso(
                'back_encerrar_processo_sem_saldo',
                authUserInfo
              ) ||
              getControleDeAcesso(
                'back_distribuicao_processo_planilha',
                authUserInfo
              ) ||
              getControleDeAcesso(
                'back_alterar_neg_acordo_planilha',
                authUserInfo
              ) ||
              getControleDeAcesso(
                'back_atualizar_status_planilha',
                authUserInfo
              ) ||
              getControleDeAcesso(
                'back_devedor_titulo_excluido',
                authUserInfo
              ) ||
              getControleDeAcesso('back_atualiza_fones', authUserInfo) ||
              getControleDeAcesso('tab_tags_marcacoes', authUserInfo) ||
              authUserInfo?.codemp == 'fc65cb' ||
              authUserInfo?.codemp == 'bo49ka' ||
              authUserInfo?.codemp == 'md22cb' ||
              authUserInfo?.codemp == 'it23sd' ||
              authUserInfo?.codemp == 'so61cb' ||
              authUserInfo?.codemp == 'hg39xv' ||
              authUserInfo?.codemp == 'slc1584',
            children: [
              {
                name: 'Baixas de Títulos por Planilha',
                type: 'item',
                ativo:
                  getControleDeAcesso(
                    'back_baixas_titulos_planilha',
                    authUserInfo
                  ) == true,
                link: '/app/backof/tools/baixas-por-planilha',
              },
              {
                name: 'Baixas de Títulos em Lote',
                type: 'item',
                ativo:
                  getControleDeAcesso(
                    'back_baixas_titulos_lote',
                    authUserInfo
                  ) == true,
                link: '/app/backof/tools/baixa-titulos-lote',
              },
              {
                name: 'Migração de Processos por Planilha',
                type: 'item',
                ativo:
                  getControleDeAcesso('back_migrar_processos', authUserInfo) ==
                  true,
                link: '/app/backof/tools/migrar-processos',
              },

              {
                name: 'Encerrar Processos sem Saldo',
                type: 'item',
                ativo:
                  getControleDeAcesso(
                    'back_encerrar_processo_sem_saldo',
                    authUserInfo
                  ) == true,
                link: '/app/backof/tools/encerrar-processos',
              },

              {
                name: 'Distribuição de Processos por Planilha',
                type: 'item',
                ativo:
                  getControleDeAcesso(
                    'back_distribuicao_processo_planilha',
                    authUserInfo
                  ) == true,
                link: '/app/backof/tools/distribuicao-processos-planilha',
              },
              {
                name: 'Atualização de Tags nos Processos por Planilha',
                type: 'item',
                ativo: getControleDeAcesso('tab_tags_marcacoes', authUserInfo),
                link: '/app/backof/tools/atualizacao-tags-processos-planilha',
              },

              {
                name: 'Alterar o Negociador do Acordo por Planilha',
                type: 'item',
                ativo:
                  getControleDeAcesso(
                    'back_alterar_neg_acordo_planilha',
                    authUserInfo
                  ) == true,
                link: '/app/backof/tools/troca-negociador-acordo',
              },
              {
                name: 'Atualização de Status/Fase do Processo por Planilha',
                type: 'item',
                ativo:
                  getControleDeAcesso(
                    'back_atualizar_status_planilha',
                    authUserInfo
                  ) == true,
                link: '/app/backof/tools/atualiza-status-processo-em-lote',
              },
              {
                name: 'Devedores/Títulos Excluídos',
                type: 'item',
                ativo:
                  getControleDeAcesso(
                    'back_devedor_titulo_excluido',
                    authUserInfo
                  ) == true,
                link: '/app/backof/tools/devedores-titulos-excluidos',
              },

              {
                name: 'Arquivo - Sercomtel',
                type: 'item',
                ativo: authUserInfo?.codemp == 'fc65cb', // FACTO
                link: '/app/backof/tools/0501',
              },
              {
                name: 'Arquivo - Digimais',
                type: 'item',
                ativo: authUserInfo?.codemp == 'fc65cb', // FACTO
                link: '/app/backof/tools/0502',
              },

              {
                name: 'Importar Recebimentos (Gera)',
                type: 'item',
                ativo: authUserInfo?.codemp == 'bo49ka', // OBOTICARIOCARIRI
                link: '/app/backof/tools/importar-recebimentos-gera',
              },
              {
                name: 'Importação CRMs Diversos',
                type: 'item',
                ativo:
                  authUserInfo?.codemp == 'bo38cg' || // BOMEN
                  authUserInfo?.codemp == 'md22cb' ||
                  authUserInfo?.codemp == 'it23sd' ||
                  authUserInfo?.codemp == 'so61cb' || // SOLIDUZ
                  authUserInfo?.codemp == 'hg39xv' || // HIUGOU
                  authUserInfo?.codemp == 'slc1584', //** Usado este hash só para testar na base teste
                link: '/app/backof/tools/importacao-crms-diversos',
              },
              {
                name: 'Atualização de Telefones por Planilha',
                type: 'item',
                ativo:
                  getControleDeAcesso('back_atualiza_fones', authUserInfo) ==
                  true,
                link: '/app/backof/tools/atualizacao-telefones-planilha',
              },
            ],
          },
        ],
      },
    ];
  } else if (tipoMenu == 'conf') {
    // menu configurações

    navigationMenus = [
      {
        name: 'Menu',
        type: 'section',
        children: [
          {
            name: 'Inicio',
            icon: 'fa fa-th-large fa-fw',
            iconSize: '1.2em',
            type: 'item',
            ativo: true,
            link: '/app/config/inicio',
          },

          {
            name: 'Gerais',
            icon: 'fa fa-cogs fa-fw',
            iconSize: '1.2em',
            type: 'item',
            ativo: getControleDeAcesso('conf_gerais', authUserInfo),
            link: '/app/config/gerais',
          },

          {
            name: 'Layouts',
            ativo: !(
              getControleDeAcesso('conf_layouts_imp', authUserInfo) == false &&
              getControleDeAcesso('conf_layouts_hig', authUserInfo) == false
            ),
            type: 'collapse',
            icon: 'fa fa-cog fa-fw',
            iconSize: '1.2em',
            children: [
              {
                name: 'Importação',
                type: 'item',
                ativo: getControleDeAcesso('conf_layouts_imp', authUserInfo),
                link: '/app/config/layout-importacao',
              },
              {
                name: 'Higienização',
                type: 'item',
                ativo: getControleDeAcesso('conf_layouts_hig', authUserInfo),
                link: '/app/config/layout-higienizacao',
              },
            ],
          },

          {
            name: 'Status',
            ativo: !(
              getControleDeAcesso('conf_status_proc', authUserInfo) == false &&
              getControleDeAcesso('conf_status_hist', authUserInfo) == false
            ),
            type: 'collapse',
            icon: 'fa fa-cog fa-fw',
            iconSize: '1.2em',
            children: [
              {
                name: 'Processo/Devedor',
                type: 'item',
                ativo: getControleDeAcesso('conf_status_proc', authUserInfo),
                link: '/app/config/status-processo',
              },
              {
                name: 'Histórico/Ocorrência',
                type: 'item',
                ativo: getControleDeAcesso('conf_status_hist', authUserInfo),
                link: '/app/config/status-ocorrencia',
              },
              {
                name: 'Classificação de Títulos',
                type: 'item',
                ativo: getControleDeAcesso('conf_classifica_tit', authUserInfo),
                link: '/app/config/classificacao-titulo',
              },
            ],
          },

          {
            name: 'Acesso / Segurança',
            ativo: !(
              getControleDeAcesso('conf_ip_liberado', authUserInfo) == false &&
              getControleDeAcesso('conf_horario_acesso', authUserInfo) ==
                false &&
              getControleDeAcesso('conf_politica_de_senhas', authUserInfo) ==
                false
            ),
            type: 'collapse',
            icon: 'fa fa-lock fa-fw',
            iconSize: '1.2em',
            children: [
              {
                name: 'Liberação de IP',
                type: 'item',
                ativo: getControleDeAcesso('conf_ip_liberado', authUserInfo),
                link: '/app/config/controle-de-acesso-ip',
              },
              {
                name: 'Horários de Acesso',
                type: 'item',
                ativo: getControleDeAcesso('conf_horario_acesso', authUserInfo),
                link: '/app/config/controle-de-acesso-horarios',
              },
              {
                name: 'Políticas de Senhas',
                type: 'item',
                ativo:
                  true ||
                  getControleDeAcesso('conf_politica_de_senhas', authUserInfo),
                link: '/app/config/politicas-de-senha',
              },
              {
                name: 'LDAP',
                type: 'item',
                ativo: true,
                link: '/app/config/ldap',
              },
            ],
          },

          {
            name: 'Contas de E-mail',
            icon: 'fa fa-envelope fa-fw',
            iconSize: '1.2em',
            type: 'item',
            ativo: getControleDeAcesso('conf_emails', authUserInfo),
            link: '/app/config/email',
          },

          {
            name: 'Níveis de Acesso',
            icon: 'fa fa-list fa-fw',
            iconSize: '1.2em',
            type: 'item',
            ativo: getControleDeAcesso('conf_niveis', authUserInfo),
            link: '/app/config/niveis-acesso',
          },

          {
            name: 'Integrações',
            icon: 'fa fa-sitemap fa-fw',
            iconSize: '1.2em',
            type: 'item',
            ativo: getControleDeAcesso('conf_gerais', authUserInfo),
            link: '/app/config/integracoes',
          },

          {
            name: 'Automações',
            icon: 'fa fa-cogs fa-fw',
            iconSize: '1.2em',
            type: 'item',
            ativo: getControleDeAcesso('automacoes', authUserInfo),
            link: '/app/config/automacoes',
          },

          {
            name: 'Produtos Adicionais',
            icon: 'fa fa-money fa-fw',
            iconSize: '1.2em',
            type: 'item',
            ativo: true,
            link: '/app/config/produtos',
          },

          {
            name: 'Cronograma de Treinamento',
            type: 'item',
            ativo: true,
            link: '/app/config/treinamento',
          },
        ],
      },
    ];
  } else if (tipoMenu == 'com') {
    // menu comercial

    navigationMenus = [
      {
        name: 'Menu',
        type: 'section',
        children: [
          {
            name: 'Inicio',
            icon: 'fa fa-th-large fa-fw',
            iconSize: '1.2em',
            type: 'item',
            ativo: true,
            link: '/app/com/inicio',
          },

          {
            name: 'Prospects',
            icon: 'fa fa-user-plus fa-fw',
            iconSize: '1.2em',
            type: 'item',
            ativo: true,
            link: '/app/com/prospects',
          },
          {
            name: 'Kanban',
            icon: 'fa fa fa-trello fa-fw',
            iconSize: '1.2em',
            type: 'item',
            ativo: true,
            link: '/app/com/kanban',
          },
          {
            name: 'Agenda',
            icon: 'fa fa-calendar fa-fw',
            iconSize: '1.2em',
            type: 'item',
            ativo: true,
            link: '/app/com/agenda-comercial',
          },
          {
            name: 'Históricos/Ocorrências',
            icon: 'fa fa-file-text-o fa-fw',
            iconSize: '1.2em',
            type: 'item',
            ativo: true,
            link: '/app/com/historico-ocorrencia',
          },
          {
            name: 'Relatórios',
            type: 'collapse',
            icon: 'fa fa-file-excel-o fa-fw',
            iconSize: '1.2em',
            children: [
              {
                name: 'Kanban - Conclusão Cartões',
                iconSize: '1.2em',
                type: 'item',
                ativo: true,
                link: '/app/com/rel-kanban-conclusao-cartoes',
              },
            ],
          },
          {
            name: 'Tabelas',
            type: 'collapse',
            icon: 'fa fa-folder-open fa-fw',
            iconSize: '1.2em',
            children: [
              {
                name: 'Prospect - Estágio / Status',
                iconSize: '1.2em',
                type: 'item',
                ativo: true,
                link: '/app/com/status-prospects',
              },
              {
                name: 'Prospect - Perfil',
                iconSize: '1.2em',
                type: 'item',
                ativo: true,
                link: '/app/com/perfil-prospects',
              },
              {
                name: 'Kanban - Motivos Conclusão Cartão',
                iconSize: '1.2em',
                type: 'item',
                ativo: true,
                link: '/app/com/motivo-conclusao-kanban',
              },
            ],
          },
          {
            name: 'Ferramentas',
            type: 'collapse',
            icon: 'fa fa-wrench',
            iconSize: '1.2em',
            children: [
              {
                name: 'Importação de Prospect',
                iconSize: '1.2em',
                type: 'item',
                ativo: true,
                link: '/app/com/prospects-importacoes',
              },
            ],
          },
        ],
      },
    ];
  } else {
    // menu cobrança
    navigationMenus = [
      {
        name: 'Menu',
        type: 'section',
        children: [
          {
            name: 'Inicio',
            icon: 'fa fa-th-large fa-fw',
            iconSize: '1.2em',
            type: 'item',
            ativo: true,
            link: '/app/cob/inicio',
          },

          {
            name: 'Devedores',
            icon: 'fa fa-user fa-fw',
            iconSize: '1.2em',
            type: 'item',
            ativo: getControleDeAcesso('cad_devedor_v', authUserInfo),
            link: '/app/cob/devedores',
          },

          {
            name: 'Acionar (Minha Carteira)',
            icon: 'fa fa-phone fa-fw',
            iconSize: '1.2em',
            type: 'item',
            ativo: getControleDeAcesso('cob_acionar', authUserInfo),
            link: '/app/cob/acionar',
          },

          {
            name: 'Agenda de Retornos',
            icon: 'fa fa-calendar-check-o fa-fw',
            iconSize: '1.2em',
            type: 'item',
            ativo: getControleDeAcesso('cob_agenda_ret', authUserInfo),
            link: '/app/cob/agenda',
          },

          {
            name: 'Acordos Programados',
            icon: 'fa fa-handshake-o fa-fw',
            iconSize: '1.2em',
            type: 'item',
            ativo: getControleDeAcesso('cob_acordos_prog', authUserInfo),
            link: '/app/cob/acordo',
          },

          {
            name: 'Instruções Online',
            icon: 'fa fa-commenting-o fa-fw',
            iconSize: '1.2em',
            type: 'item',
            ativo: getControleDeAcesso('instrucao_online', authUserInfo),
            link: '/app/cob/instrucao-online',
          },

          {
            name: 'Diversos',
            icon: 'fa fa-star fa-fw',
            iconSize: '1.2em',
            type: 'collapse',
            ativo: !(
              getControleDeAcesso('cob_comissao', authUserInfo) == false &&
              getControleDeAcesso('cob_boletos', authUserInfo) == false &&
              getControleDeAcesso('cob_pix', authUserInfo) == false &&
              getControleDeAcesso('cob_historicos', authUserInfo) == false &&
              getControleDeAcesso('cob_acordos_lib', authUserInfo) == false &&
              getControleDeAcesso('cob_recident', authUserInfo) == false
            ),
            children: [
              {
                name:
                  authUserInfo?.codemp == 'fc65cb' &&
                  authUserInfo?.perfil == 'COMERCIAL'
                    ? 'Bonificações'
                    : 'Comissões',
                type: 'item',
                ativo: getControleDeAcesso('cob_comissao', authUserInfo),
                link: '/app/cob/relatorio-comissao',
              },
              {
                name: 'Boletos Emitidos',
                type: 'item',
                ativo: getControleDeAcesso('cob_boletos', authUserInfo),
                link: '/app/cob/boletos',
              },
              {
                name: 'Pix QrCode',
                type: 'item',
                ativo: getControleDeAcesso('cob_pix', authUserInfo),
                link: '/app/cob/pix',
              },
              {
                name: 'Históricos/Ocorrências',
                type: 'item',
                ativo: getControleDeAcesso('cob_historicos', authUserInfo),
                link: '/app/cob/ocorrencias',
              },
              {
                name: 'Acordos Liberados',
                type: 'item',
                ativo: getControleDeAcesso('cob_acordos_lib', authUserInfo),
                link: '/app/cob/acordos-liberados',
              },
              {
                name: 'Propostas Realizadas',
                type: 'item',
                ativo: getControleDeAcesso('cob_propostas', authUserInfo),
                link: '/app/cob/propostas-realizadas',
              },
              {
                name: 'Receb. à Identificar',
                type: 'item',
                ativo: getControleDeAcesso('cob_recident', authUserInfo),
                link: '/app/cob/bloqueados-identificar',
              },
              {
                name: 'Modelos de Ocorrências',
                type: 'item',
                ativo: getControleDeAcesso('cob_modelo_oco', authUserInfo),
                link: '/app/cob/modelos-ocorrencias',
              },

              /*
              {
                name: 'Digitalizações / Documentos',
                type: 'item',
                ativo: true,
                link: '/app/xxx',
                bloq: true,
              },
              */
            ],
          },
        ],
      },
    ];
  }

  return (
    <CustomScrollbars className=" scrollbar">
      <Navigation menuItems={navigationMenus} />
    </CustomScrollbars>
  );
};

export default SideBarContent;
