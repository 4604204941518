import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Switch,
  TextField,
} from '@material-ui/core';

import googleIcon from '../../../../../styles/icons/google.svg';
import api from 'services/api';
import Loading from 'components/Utils/loading';
import SnackbarNotification from 'components/SnackbarNotification';

const styles = (theme) => (
{
  cardBody: 
  {
    padding: theme.spacing(3),
    backgroundColor: '#f5f5f5',
  },
  switchContainer: 
  {
    display: 'grid',
    gridTemplateColumns: '3fr 1fr',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  switchLabel: 
  {
    display: 'flex',
    alignItems: 'center',
  },
  heading: 
  {
    fontWeight: 'light',
    marginBottom: theme.spacing(2),
  },
  instructions: 
  {
    listStyleType: 'disc',
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  qrCodeContainer: 
  {
    width: '100%',
    textAlign: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  qrCode: 
  {
    maxWidth: '100%',
  },

  '@media (max-width: 1366px)': {
    qrCode: {
      maxWidth: '140px', 
    },
    heading: 
    {
      fontSize: '20px'
    },
  },
  '@media (max-width: 1144px)': {
    qrCode: {
      maxWidth: '80px', 
    },
    heading: 
    {
      fontSize: '16px'
    },
  },
});

const DialogCadastro2FA = ({ classes, ...props }) => {
  const [checked2FA, setChecked2FA] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageURL, setImageURL] = useState('');
  const [ativaQrCode, setAtivaQrCode] = useState(true);
  const [code2FA, setCode2FA] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const [alertInfoTipo, setAlertInfoTipo] = useState('');
  const [alertInfo, setAlertInfo] = useState('');
  const [tokenSecret, setTokenSecret] = useState('');

  useEffect(() => 
  {
    if (props.openCadastro2FA) 
    {
      if (props.getChecked2FA == 'Ativo') setChecked2FA(true);
      else setChecked2FA(false);
    }
  }, [props.openCadastro2FA]);

  function capitalizeWords(str) {
    return str
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  async function confere2FA() 
  {
    if (code2FA != '') 
    {
      try 
      {
        const code2FATrim = code2FA.replace(/\s/g, '');

        const response = await api.post(
          `/google_authenticator_verify/${null}`,
          {
            data: 
            {
              code2FA: code2FATrim, tokenSecret,
            },
          }
        );

        if (response.status === 200) 
        {
          props.setToken2FA(tokenSecret);
          setAtivaQrCode(true);
          setCode2FA('');
          setImageURL('');

          if(props.display == 'menu')
            props.hanfleSalvarInfo2FA(tokenSecret)
          props.close();
        }
      } catch (error) 
      {
        setAlertInfo('Código inválido, tente novamente!');
        setAlertInfoTipo('mb-3 bg-danger');
        setOpenAlert(true);
      }
    } else 
    {
      setAlertInfo('Campo "Código 2FA" obrigatório!');
      setAlertInfoTipo('mb-3 bg-warning');
      setOpenAlert(true);
    }
  }

  async function carregarQrCode() 
  {
    try 
    {
      setLoading(true);

      const nome2FA = (capitalizeWords(props.nome2FA.usuario) + '-' + capitalizeWords(props.nome2FA.razaoEmp));

      const response = await api.post(`/google_authenticator_generate`, 
      {
        nome2FA,
      });

      const data = await response.data;

      setLoading(false);
      setImageURL(data.imageURL);
      setTokenSecret(data.secret);
    } catch (error) 
    {
      throw new Error('Erro ao gerar qrcode');
    }
  }

  const handleOpen2FA = () => 
  {
    carregarQrCode();
    setAtivaQrCode(false);
  };

  const ativarDesativar2FA = () => 
  {
    if (props.getChecked2FA == 'Inativo') 
    {
      setChecked2FA(true);
      props.onChangeChecked2FA('Ativo');
    } else 
    {
      setChecked2FA(false);
      props.onChangeChecked2FA('Inativo');
    }

    setAtivaQrCode(true);
    setCode2FA('');
    setImageURL('');
  };

  return (
    <Dialog
      open={props.openCadastro2FA}
      onClose={props.close}
      fullWidth
      maxWidth="sm"
    >
      <DialogContent className={classes.cardBody}>
        <div className={classes.switchContainer}>
          <img src={googleIcon} style=
          {{ 
            width: '35px', 
            height: '35px',
          }} />
          <div
            className={classes.switchLabel}
            hidden={props.display == 'menu' && checked2FA}
          >
            <Typography component="div">Inativo</Typography>
            <Switch
              size="small"
              name="ck_ativo"
              color="primary"
              checked={checked2FA}
              onChange={ativarDesativar2FA}
            />
            <Typography component="div">Ativo</Typography>
          </div>
        </div>
        <h1 className={classes.heading}>Configurar Autenticador</h1>
        <ul className={classes.instructions}>
          <li>
            Obtenha o aplicativo Google Authenticator na{' '}
            <strong>
              <a
                href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=pt_BR&gl=US"
                target="_blank"
              >
                Play Store
              </a>
            </strong>
          </li>
          <li>
            No aplicativo, selecione <strong>Configurar Conta</strong>
          </li>
          <li>
            Escolha <strong>Escanear um Código de Barras</strong>
          </li>
          <li>
            Clique em <strong>Fechar</strong> para <strong>Salvar</strong>
          </li>
        </ul>

        <div className={classes.qrCodeContainer}>
          <img src={imageURL} className={classes.qrCode} hidden={ativaQrCode} />
          <div className="mt-2">
            <Button
              variant="contained"
              color="primary"
              className="jr-btn-sm text-white"
              onClick={handleOpen2FA}
              disabled={!checked2FA}
            >
              <span>GERAR QR CODE</span>
            </Button>
          </div>
          <div className="mt-4" hidden={ativaQrCode}>
            <TextField
              size="small"
              style=
              {{ 
                width: 150 
              }}
              InputLabelProps=
              {{
                shrink: true,
              }}
              inputProps=
              {{
                style: {
                  textAlign: 'center',
                },
              }}
              max={6}
              value={code2FA}
              onChange={(e) => 
              {
                const input = e.target.value;
                const formattedValue = input
                  .replace(/\D/g, '')
                  .replace(/(\d{3})(?=\d)/g, '$1 ')
                  .substring(0, 7);
                setCode2FA(formattedValue);
              }}
              label="Código 2FA"
              type="text"
              variant="outlined"
              color="primary"
              fullWidth
            />
          </div>
        </div>
      </DialogContent>

      <DialogActions style={{ backgroundColor: '#f5f5f5' }}>
        <Button onClick={confere2FA} color="primary" hidden={ativaQrCode}>
          Aplicar
        </Button>
        <Button onClick={props.close}>Fechar</Button>
      </DialogActions>

      <Loading carregar={loading} />

      <SnackbarNotification
        openAlert={openAlert}
        alertInfoTipo={alertInfoTipo}
        alertInfo={alertInfo}
        callbackParent={() => setOpenAlert(false)}
      />
    </Dialog>
  );
};

export default withStyles(styles)(DialogCadastro2FA);
