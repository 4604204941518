import React from 'react';
import AppLayouts from './AppLayouts';
import Routes from '../../app/routes';
import { useSelector } from 'react-redux';

import { LicenseInfo } from '@mui/x-license-pro';

const AppLayout = () => {
  LicenseInfo.setLicenseKey(
    'ab28d2678ec897fb5450ccecc171d505Tz05ODY5MCxFPTE3NTg3NDAxMzYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPVEzLTIwMjQsS1Y9Mg=='
  );

  const horizontalNavPosition = useSelector(
    ({ settings }) => settings.horizontalNavPosition
  );
  const navigationStyle = useSelector(
    ({ settings }) => settings.navigationStyle
  );
  const onGetLayout = (layout) => {
    switch (layout) {
      case 'inside_the_header':
        return 'InsideHeaderNav';

      case 'above_the_header':
        return 'AboveHeaderNav';

      case 'below_the_header':
        return 'BelowHeaderNav';
      default:
        return 'Vertical';
    }
  };

  const Layout =
    AppLayouts[
      navigationStyle === 'vertical_navigation'
        ? 'Vertical'
        : onGetLayout(horizontalNavPosition)
    ];
  return (
    <Layout>
      <Routes />
    </Layout>
  );
};

export default AppLayout;
