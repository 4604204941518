import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const Footer = () => {
  const authUserInfo = useSelector(({ auth }) => auth.authUserInfo);

  const location = useLocation();
  const pathFullMenu = location.pathname;
  const pathMenuPos = pathFullMenu.indexOf('/', 6);
  let pathFull = pathFullMenu;
  if (pathMenuPos > 0) {
    pathFull = pathFullMenu.substring(0, pathMenuPos);
  }

  let modulo = '';
  if (pathFull == '/app/config') {
    modulo = 'Configurações';
  } else if (pathFull == '/app/rel') {
    modulo = 'Relatórios';
  } else if (pathFull == '/app/cad') {
    modulo = 'Cadastros';
  } else if (pathFull == '/app/backof') {
    modulo = 'BackOffice';
  } else if (pathFull == '/app/fin') {
    modulo = 'Financeiro';
  } else if (pathFull == '/app/com') {
    modulo = 'Comercial';
  } else {
    modulo = 'Cobrança';
  }

  return (
    <footer className="app-footer">
      {authUserInfo && authUserInfo.white_label == false && (
        <span className="d-inline-block">
          &copy; CobCloud 2024 | Versão 2.1.0.0 | App {modulo}
        </span>
      )}

      {authUserInfo && authUserInfo.white_label == true && (
        <span className="d-inline-block" hidden={authUserInfo?.white_label}>
          Versão 2.0.24.0 | App {modulo}
        </span>
      )}

      <span className="d-inline" style={{ color: '#000' }}>
        {authUserInfo != null
          ? `${authUserInfo.fantasia} - USUÁRIO: ${authUserInfo.ac_login} - PERFIL: ${authUserInfo.perfil}`
          : ''}
      </span>

      {/*
      <Button
        href="https://codecanyon.net/cart/configure_before_adding/20978545?license=regular&ref=phpbits&size=source&support=bundle_12month&_ga=2.172338659.1340179557.1515677375-467259501.1481606413"
        target="_blank"
        size="small"
        color="primary"
      />
    */}
    </footer>
  );
};
export default Footer;
