/* eslint-disable react-hooks/exhaustive-deps */
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import React, { useEffect, useRef, useState } from 'react';
import SnackbarNotification from 'components/SnackbarNotification';
import formatMoney from 'util/formatMoney';
import { parse } from 'path';

const useStyles = makeStyles(() => ({
  inputUpper: {
    textTransform: 'uppercase',
  },

  style_error: {
    fontSize: 11,
    color: '#ff0000',
  },
  tableCellBold: {
    fontWeight: 'bold',
  },
  iconbutton_grid: {
    margin: 0,
    marginRight: '10px',
    padding: 1,
  },
}));

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#F5F5F5',
    fontSize: '14px',
    padding: 3,
    whiteSpace: 'nowrap',
  },
  body: {
    fontSize: '14px',
    padding: 5,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))(TableCell);

const StyledTableFooter = withStyles(() => ({
  body: {
    backgroundColor: '#e6e6e6',
    fontSize: '14px',
    fontWeight: 'bold',
    padding: 5,
    whiteSpace: 'nowrap',
  },
}))(TableCell);

const DataTableHead = (props) => {
  const { columnData } = props;

  return (
    <TableHead>
      <TableRow>
        {columnData.map((column) => {
          return (
            <StyledTableCell
              hidden={column.hidden || false}
              key={column.id}
              align={column.align}
              padding={column.disablePadding ? 'normal' : 'checkbox'}
            >
              <div>{column.label}</div>
            </StyledTableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export default function TableTitulos(props) {
  const classes = useStyles();
  const [alertInfoTipo, setAlertInfoTipo] = useState();
  const [openAlert, setOpenAlert] = useState(false);
  const [alertInfo, setAlertInfo] = useState();
  const [selected, setSelected] = useState([]);
  const [titulos, setTitulos] = useState([]);
  const [totalValor, setTotalValor] = useState(0);
  const [totalProtesto, setTotalProtesto] = useState(0);
  const [totalPago, setTotalPago] = useState(0);
  const [taxasPd, setTaxasPd] = useState([]);
  const [editingCell, setEditingCell] = useState(null);
  const inputRef = useRef(null);

  useEffect(() => {
    if (props.titulos) {
      setTitulos(props.titulos);
    }
  }, [props.titulos]);

  useEffect(() => {
    if (selected.length > 0) {
      const indexTit = titulos.findIndex(
        (tit) => tit.hash_titulo === selected[selected.length - 1].hash_titulo
      );
      setEditingCell(selected[indexTit]);
    } else {
      setEditingCell(null);
    }
  }, [selected]);

  useEffect(() => {
    if (editingCell) {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  }, [editingCell]);

  useEffect(() => {
    if (titulos.length > 0) {
      calcularTotalPagoDinamico();

      titulos.map((row) => {
        calcularValorPd(row);
      });
    }
  }, [titulos]);

  useEffect(() => {
    const gerarTotais = () => {
      const somaValor = titulos.reduce(function (total, linha) {
        return total + parseFloat(linha.valor);
      }, 0);
      setTotalValor(somaValor);

      const somaProtesto = titulos.reduce(function (total, linha) {
        return total + parseFloat(linha.protesto);
      }, 0);
      setTotalProtesto(somaProtesto);
    };

    if (titulos && titulos.length > 0) {
      gerarTotais();
    }
  }, [titulos]);

  const columnData = [
    {
      id: 'especie',
      align: 'left',
      disablePadding: true,
      label: 'Espécie',
    },
    {
      id: 'ntitulo',
      align: 'left',
      disablePadding: true,
      label: 'N° Título',
    },
    {
      id: 'parcela',
      align: 'left',
      disablePadding: true,
      label: 'Parcela',
    },
    {
      id: 'vencimento',
      align: 'center',
      disablePadding: true,
      label: 'Vencimento',
    },
    {
      id: 'valor',
      align: 'right',
      disablePadding: true,
      label: 'Valor',
    },
    {
      id: 'protesto',
      align: 'right',
      disablePadding: true,
      label: 'Protesto',
    },
    {
      id: 'total_pago',
      align: 'right',
      disablePadding: true,
      label: 'Total Pago',
    },
    {
      id: 'taxa_pd',
      align: 'left',
      disablePadding: false,
      label: 'Taxa Pd.',
    },
    {
      id: 'valor_pd',
      align: 'right',
      disablePadding: false,
      label: 'Valor Pd.',
    },
  ];

  function calcularTotalPagoDinamico() {
    let total = 0;
    const titulosPago = titulos[0]?.titulos_alega.titulosAlega;

    titulosPago.forEach((tit) => {
      total += parseFloat(tit.valor_pago.replace(',', '.'));
    });
    setTotalPago(total);
  }

  const calcularValorPd = (row) => {
    const titulosPago = row?.titulos_alega?.titulosAlega;
    const updatedTaxasPd = titulosPago.map((tit) => {
      const tituloData = titulos.find(
        (titulo) => titulo.hash_titulo === tit.hash_titulo
      );
      if (tituloData) {
        const percentual = tituloData.taxa_pd;
        tit.valor_pd = (
          (parseFloat(tit.valor_pago.replace(',', '.')) * percentual) /
          100
        ).toFixed(2);

        return {
          hash_titulo: row?.hash_titulo,
          taxa_pd: percentual,
          valor_pd: tit.valor_pd,
        };
      }
    });
    if (updatedTaxasPd.length > 0) {
      setTaxasPd(updatedTaxasPd);
    }
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small">
          <DataTableHead columnData={columnData} />

          <TableBody>
            {titulos.map((row, index) => {
              return (
                <TableRow hover key={index}>
                  <StyledTableCell align="left">{row.especie}</StyledTableCell>
                  <StyledTableCell align="left">{row.ntitulo}</StyledTableCell>
                  <StyledTableCell align="left">{row.parcela}</StyledTableCell>
                  <StyledTableCell align="center">
                    {row.vencimento}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{ width: '90px' }}
                    align="right"
                  >{`R$ ${formatMoney(row.valor)}`}</StyledTableCell>
                  <StyledTableCell align="right">{`R$ ${formatMoney(
                    row.protesto
                  )}`}</StyledTableCell>
                  <StyledTableCell
                    align="right"
                    style={{
                      backgroundColor: selected?.some(
                        (elem) => elem.hash_titulo === row.hash_titulo
                      )
                        ? '#ffffb3'
                        : '',
                    }}
                    ref={selected?.includes(row) ? inputRef : null}
                  >
                    <span>
                      {editingCell === row
                        ? ''
                        : `R$ ${parseFloat(
                            row.titulos_alega.titulosAlega.find(
                              (tit) => tit.hash_titulo === row.hash_titulo
                            ).valor_pago
                          )
                            .toFixed(2)
                            .replace('.', ',')}`}
                    </span>
                  </StyledTableCell>
                  <StyledTableCell
                    contentEditable
                    suppressContentEditableWarning
                    style={{
                      backgroundColor: '#ffffb3',
                    }}
                    onFocus={(e) => {
                      const valor = e.target.textContent;
                      if (valor.endsWith('%')) {
                        e.target.textContent = valor.substring(
                          0,
                          valor.length - 1
                        );
                      }
                      const range = document.createRange();
                      range.selectNodeContents(e.target);
                      const selection = window.getSelection();
                      selection.removeAllRanges();
                      selection.addRange(range);
                    }}
                    onInput={(e) => {
                      const newValue = e.currentTarget.textContent;
                      if (newValue === '') {
                        e.currentTarget.textContent = ' ';
                      }
                      const sanitizedValue = newValue.replace(/[^\d,.]/g, '');
                      const formattedValue = sanitizedValue.replace(',', '.');
                      row.taxa_pd = formattedValue;

                      const isValorJaAdicionado = taxasPd.some(
                        (valor) => valor.hash_titulo === row.hash_titulo
                      );

                      if (isValorJaAdicionado) {
                        const titulo = taxasPd.find(
                          (tit) => tit.hash_titulo === row.hash_titulo
                        );
                        titulo.taxa_pd = formattedValue;
                      } else {
                        setTaxasPd([
                          ...taxasPd,
                          {
                            hash_titulo: row.hash_titulo,
                            taxa_pd: formattedValue,
                            valor_pd: row.valor_pd,
                          },
                        ]);
                      }
                    }}
                    onBlur={(e) => {
                      const valor = e.target.innerText.replace('%', '');
                      let sanitizedValue = valor.replace(/[^\d,.]/g, '');
                      sanitizedValue = sanitizedValue.replace(',', '.');
                      if (parseFloat(sanitizedValue) > 0) {
                        e.currentTarget.textContent = `${parseFloat(
                          sanitizedValue
                        )
                          .toFixed(2)
                          .replace('.', ',')}%`;
                      } else {
                        e.currentTarget.textContent = `0,00%`;
                      }
                      calcularValorPd(row);
                      setEditingCell(null);
                      calcularTotalPagoDinamico();
                    }}
                    onKeyDown={(e) => {
                      const allowedKeys = [
                        'Backspace',
                        'Tab',
                        'ArrowLeft',
                        'ArrowRight',
                        'Delete',
                        'Decimal',
                        'Home',
                        'End',
                        'Shift',
                        '.',
                        ',',
                      ];

                      if (
                        !allowedKeys.includes(e.key) &&
                        isNaN(Number(e.key))
                      ) {
                        e.preventDefault();
                      }
                    }}
                    align="center"
                  >
                    {parseFloat(row.taxa_pd).toFixed(2).replace('.', ',')}%
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {`R$ ${
                      row.titulos_alega.titulosAlega.find(
                        (tit) => tit.hash_titulo === row.hash_titulo
                      ).valor_pd
                    }`.replace('.', ',')}
                  </StyledTableCell>
                </TableRow>
              );
            })}
          </TableBody>
          <TableBody>
            <TableRow>
              <StyledTableFooter colSpan="4" />
              <StyledTableFooter align="right">
                {`R$ ${formatMoney(totalValor)}`}
              </StyledTableFooter>
              <StyledTableFooter align="right">
                {`R$ ${formatMoney(totalProtesto)}`}
              </StyledTableFooter>
              <StyledTableFooter align="right">
                {`R$ ${formatMoney(totalPago)}`}
              </StyledTableFooter>
              <StyledTableFooter colSpan="3" />
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <SnackbarNotification
        openAlert={openAlert}
        alertInfoTipo={alertInfoTipo}
        alertInfo={alertInfo}
        callbackParent={() => setOpenAlert(false)}
      />
    </>
  );
}
