import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Button,
  Typography,
} from '@material-ui/core';

import api from 'services/api';
import SnackbarNotification from 'components/SnackbarNotification';
import Loading from 'components/Utils/loading';

const useStyles = makeStyles((theme) => ({
  style_error: {
    fontSize: 11,
    color: '#ff0000',
  },
  inputUpper: {
    textTransform: 'uppercase',
  },
}));

const AlterarSenha = ({
  isOpen,
  callbackParentFechar,
  callbackParentSalvar,
}) => {
  const classes = useStyles();

  const { handleSubmit, setValue, register, errors, getValues, watch } =
    useForm();
  const authUserInfo = useSelector(({ auth }) => auth.authUserInfo);

  const [alertInfo, setAlertInfo] = useState();
  const [alertInfoTipo, setAlertInfoTipo] = useState();
  const [loading, setLoading] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [tamMinimoSenha, setTamMinimoSenha] = useState(0);
  const [configSenha, setConfigSenha] = useState([]);

  useEffect(() => {
    if (isOpen) {
      let usuario = '';
      if (authUserInfo) usuario = authUserInfo?.ac_login;
      setTimeout(() => {
        setValue('tx_usuario', usuario);
        setValue('tx_senha', '');
        setValue('tx_nova_senha', '');
      });

      CarregarConfigSenhas();
    }
  }, [isOpen]);

  function verificarSenhasCaracteres(senha, config) {
    let senhaComNumero;
    if (config[0].exigir_numero) {
      const regex = /\d+/;
      senhaComNumero = regex.exec(senha);
    }

    let senhaMaiuscula = '';
    if (config[0].exigir_letra_maiscula) {
      const regex = /[A-Z]/;
      senhaMaiuscula = regex.exec(senha);
    }

    let senhaMinuscula;
    if (config[0].exigir_letra_minuscula) {
      const regex = /[a-z]/;
      senhaMinuscula = regex.exec(senha);
    }

    let senhaComCaractere = '';
    if (config[0].exigir_caractere_especial) {
      const verificarCaractereEspecial =
        /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
      senhaComCaractere = verificarCaractereEspecial.exec(senha);
    }

    if (config[0].exigir_caractere_especial && !senhaComCaractere) {
      throw new Error(
        'A senha precisa ter pelo menos um Caractere Especial (ex: ! , @, ?, ;)'
      );
    }

    if (config[0].exigir_numero && !senhaComNumero) {
      throw new Error('A senha deve ter pelo menos um número.');
    }

    if (config[0].exigir_letra_maiscula && !senhaMaiuscula) {
      throw new Error('A senha deve ter pelo menos um caractere Maiusculo.');
    }

    if (config[0].exigir_letra_minuscula && !senhaMinuscula) {
      throw new Error('A senha deve ter pelo menos um caractere Minusculo.');
    }
  }

  const CarregarConfigSenhas = useCallback(async () => {
    const response = await api.get(`/carregar_politica_senhas/`);
    const data = response.data.rows;
    setConfigSenha(data);
    setTamMinimoSenha(data[0].quantidade_minima_de_caracteres);
  }, []);

  async function onSubmitForm(data) {
    setOpenAlert(true);

    let idUsuario = '';
    if (authUserInfo != null) {
      idUsuario = authUserInfo.id_uuid;
    }

    try {
      verificarSenhasCaracteres(getValues('tx_senha'), configSenha);

      if (getValues('tx_senha') !== getValues('tx_nova_senha')) {
        setAlertInfo('Erro. Senhas não são iguais.');
        setAlertInfoTipo('mb-3 bg-danger');
        setLoading(false);
      } else {
        setLoading(true);
        data.tx_acesso_senha = data.tx_nova_senha;
        const response = await api.put(`/alterar_senha/${idUsuario}`, { data });

        if (response.status < 400) {
          setAlertInfo('Senha alterada com sucesso');
          setAlertInfoTipo('mb-3 bg-success');
          setOpenAlert(true);

          callbackParentSalvar();
          setLoading(false);
        }
      }
    } catch (error) {
      setLoading(false);
      setAlertInfo(`Erro: ${error.message}`);
      setAlertInfoTipo('mb-3 bg-danger');
      setOpenAlert(true);
    }
  }

  const handleClickCloseAlert = () => {
    setOpenAlert(false);
  };

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => callbackParentFechar()}
        maxWidth="xs"
        fullWidth
      >
        <form onSubmit={handleSubmit(onSubmitForm)} autoComplete="off">
          <DialogTitle id="alert-dialog-title">Alterar Senha</DialogTitle>
          <DialogContent>
            <div className="row mb-2">
              <div className="col-12">
                <TextField
                  disabled
                  className="mt-2"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="tx_usuario"
                  id="tx_usuario"
                  margin="dense"
                  label="Usuário"
                  fullWidth
                  variant="outlined"
                  inputRef={register}
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-12">
                <TextField
                  className="mt-2"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="tx_senha"
                  id="tx_senha"
                  margin="dense"
                  label="Digite a nova senha"
                  fullWidth
                  variant="outlined"
                  type="password"
                  inputRef={register({
                    minLength: tamMinimoSenha,
                    required: 'Campo Obrigatório',
                  })}
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-12">
                <TextField
                  className="mt-2"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="tx_nova_senha"
                  id="tx_nova_senha"
                  margin="dense"
                  label="Confirme a nova senha"
                  fullWidth
                  variant="outlined"
                  type="password"
                  inputRef={register({
                    minLength: tamMinimoSenha,
                    required: 'Campo Obrigatório',
                  })}
                />
                {(errors.tx_nova_senha || errors.tx_senha) && (
                  <Typography variant="span" className={classes.style_error}>
                    {`O tamanho mínimo é de ${tamMinimoSenha} caracteres`}
                  </Typography>
                )}
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button type="submit" color="primary">
              Gravar
            </Button>
            <Button onClick={() => callbackParentFechar()}>Fechar</Button>
          </DialogActions>
        </form>
      </Dialog>
      {/** efeito fade com spiner de carregando */}
      <Loading carregar={loading} />

      <SnackbarNotification
        openAlert={openAlert}
        alertInfoTipo={alertInfoTipo}
        alertInfo={alertInfo}
        callbackParent={() => handleClickCloseAlert()}
      />
    </>
  );
};

export default AlterarSenha;
