import React, { useEffect, useState } from 'react';
import {
  FormControl,
  InputLabel,
  NativeSelect,
  TextField,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import Loading from 'components/Utils/loading';
import Moment from 'moment';

export default function InfosRecebimento(props) {
  const { register, setValue } = useForm();
  const {
    handleSelectPlanoDeConta,
    handleSelectFormaPagto,
    handleChangeDataRecebimento,
    listarFormaPagto,
    listarPlanoDeConta,
    formasPagto: listaFormaPagto,
    planosDeConta: listaPlanodeConta,
  } = props;

  useEffect(() => {
    if (props.titulos.length > 0) {
      const { titulos } = props;
      setTimeout(() => {
        setValue('tx_processo', titulos[0]?.processo);
        setValue(
          'tx_data_rec',
          Moment(titulos[0]?.data_confirmado, 'DD/MM/YYYY').format('YYYY-MM-DD')
        );
        setValue('tx_forma_pagto', 'BOLETO');
      });
      listarPlanoDeConta();
      listarFormaPagto();
    }
  }, [props.titulos]);

  return (
    <>
      <div className="row mb-2">
        <div className="col-3">
          <TextField
            className="mt-2"
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            name="tx_data_rec"
            id="tx_data_rec"
            margin="dense"
            label="Data do Recebimento"
            type="date"
            fullWidth
            onChange={(e) => handleChangeDataRecebimento(e.target.value)}
            inputRef={register({
              required: 'Campo obrigatório !!!',
            })}
          />
        </div>
        <div className="col-3">
          <TextField
            autoFocus
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              maxLength: 20,
              name: 'tx_processo',
              id: 'tx_processo',
            }}
            InputProps={{
              readOnly: true,
            }}
            margin="dense"
            label="Processo"
            type="text"
            fullWidth
            inputRef={register()}
          />
        </div>
        <div className="col-6">
          <FormControl className="w-100" margin="dense">
            <InputLabel htmlFor="tx_forma_pagto" shrink>
              Forma de Pagamento
            </InputLabel>

            <NativeSelect
              id="tx_forma_pagto"
              name="tx_forma_pagto"
              defaultValue=""
              onChange={(e) => handleSelectFormaPagto(e.target.value)}
              inputRef={register()}
            >
              {listaFormaPagto?.map((item, index) => (
                <option key={index} value={`${item.descricao}`}>
                  {item.descricao}
                </option>
              ))}
            </NativeSelect>
          </FormControl>
        </div>
      </div>
      <div className="row mb-1">
        <div className="col-12 mb-2">
          <FormControl className="w-100" margin="dense">
            <InputLabel htmlFor="combo_plano_conta" shrink>
              Plano de Conta
            </InputLabel>

            <NativeSelect
              id="combo_plano_conta"
              name="combo_plano_conta"
              defaultValue=""
              onChange={(e) => handleSelectPlanoDeConta(e.target.value)}
              inputRef={register()}
            >
              <option value={0} hidden={listaPlanodeConta?.length > 0}>
                Selecione o Plano de Conta
              </option>
              {listaPlanodeConta?.map((plano, index) => (
                <option key={index} value={plano.id}>
                  {plano.codigo} - {plano.descricao}
                </option>
              ))}
            </NativeSelect>
          </FormControl>
        </div>
      </div>
    </>
  );
}
