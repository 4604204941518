import React, { useEffect } from 'react';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import URLSearchParams from 'url-search-params';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';
import 'assets/vendors/style';

import {
  AMBER,
  BLUE,
  CYAN,
  DARK_AMBER,
  DARK_BLUE,
  DARK_CYAN,
  DARK_DEEP_ORANGE,
  DARK_DEEP_PURPLE,
  DARK_GREEN,
  DARK_INDIGO,
  DARK_PINK,
  DEEP_ORANGE,
  DEEP_PURPLE,
  GREEN,
  INDIGO,
  PINK,
} from 'constants/ThemeColors';
import RTL from 'util/RTL';
import { isAuthenticated, getToken2FA } from 'util/cobUtils';

import erro404 from 'app/routes/extraPages/routes/404';
import indigoTheme from './themes/indigoTheme';
import cyanTheme from './themes/cyanTheme';
import orangeTheme from './themes/orangeTheme';
import amberTheme from './themes/amberTheme';
import pinkTheme from './themes/pinkTheme';
import blueTheme from './themes/blueTheme';
import purpleTheme from './themes/purpleTheme';
import greenTheme from './themes/greenTheme';
import AppLocale from '../lngProvider';
// import SignInOld from './SignIn-old';
import SignIn from './SignIn';
import { setInitUrl, userSignOut, getUser } from '../actions/Auth';
import { setDarkTheme, setThemeColor } from '../actions/Setting';
import AppLayout from './AppLayout';
import Code2FA from './Code2FA';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { v4 as uuidv4 } from 'uuid';

const RestrictedRoute = ({ component: Component, authUser, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      authUser ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

const App = (props) => {
  const dispatch = useDispatch();
  const { themeColor, darkTheme, locale, isDirectionRTL } = useSelector(
    ({ settings }) => settings
  );
  const { authUser, authUserInfo, initURL } = useSelector(({ auth }) => auth);
  const isDarkTheme = darkTheme;
  const { match, location } = props;

  const authUser2FA = useSelector(({ auth }) => auth.authenticated_2f2);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const checkIfAppAlreadyOpen = () => {
      const tabId = uuidv4();

      const storedTabId = localStorage.getItem('tabId');

      if (!storedTabId) {
        localStorage.setItem('tabId', tabId);
      } else if (storedTabId !== tabId) {
        window.close();
      }

      window.addEventListener('beforeunload', () => {
        localStorage.removeItem('tabId');
      });
    };

    if (authUserInfo?.mono_tab) checkIfAppAlreadyOpen();

    handleHorarioAtiva(authUserInfo);
  }, [authUserInfo]);

  useEffect(() => {
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
    if (initURL === '') {
      dispatch(setInitUrl(props.history.location.pathname));
    }

    const params = new URLSearchParams(props.location.search);
    if (params.has('theme-name')) {
      dispatch(setThemeColor(params.get('theme-name')));
    }

    if (params.has('dark-theme')) {
      dispatch(setDarkTheme());
    }
  }, [
    dispatch,
    initURL,
    props.history.location.pathname,
    props.location.search,
  ]);

  useEffect(() => {
    if (props.history.location.pathname !== '/login') {
      if (isAuthenticated() == false) {
        dispatch(userSignOut());
      }

      // recarrega no redux as informações do usuario
      if (authUserInfo == null) {
        dispatch(getUser());
      }
    }
  }, [props.history.location.pathname]);

  const _converte_data = (timeString) => {
    if (timeString) {
      const [hours, minutes, seconds] = timeString.split(':');
      const date = new Date();
      date.setHours(parseInt(hours, 10));
      date.setMinutes(parseInt(minutes, 10));
      date.setSeconds(parseInt(seconds, 10));

      return date;
    }
  };

  const countdown = (time, message, duration, action = () => '') => {
    setTimeout(() => {
      enqueueSnackbar(message, {
        variant: 'warning',
        autoHideDuration: duration,
      });
      action();
    }, time);
  };

  const handleHorarioAtiva = (authUserInfo) => {
    if (authUserInfo?.horarios && authUserInfo.horarios.horaHf) {
      const horaHf = _converte_data(authUserInfo.horarios.horaHf);
      const horarioHoje = _converte_data(authUserInfo.horarios.horarioHoje);

      const diferencaEmMilissegundos = horaHf.getTime() - horarioHoje.getTime();

      const warnings = [
        {
          time: diferencaEmMilissegundos - 5 * 60 * 1000,
          message: 'Atenção, sua sessão encerra em 5 minutos',
          duration: 5000,
          action: () => '',
        },
        {
          time: diferencaEmMilissegundos - 3 * 60 * 1000,
          message: 'Atenção, sua sessão encerra em 3 minutos',
          duration: 5000,
          action: () => '',
        },
        {
          time: diferencaEmMilissegundos - 1 * 60 * 1000,
          message: 'Atenção, sua sessão encerra em 1 minuto',
          duration: 5000,
          action: () => '',
        },
        {
          time: diferencaEmMilissegundos - 10 * 1000,
          message: 'Atenção, sua sessão encerra em 10 segundos',
          duration: 1000,
          action: () => '',
        },
        {
          time: diferencaEmMilissegundos - 9 * 1000,
          message: 'Atenção, sua sessão encerra em 9 segundos',
          duration: 1000,
          action: () => '',
        },
        {
          time: diferencaEmMilissegundos - 8 * 1000,
          message: 'Atenção, sua sessão encerra em 8 segundos',
          duration: 1000,
          action: () => '',
        },
        {
          time: diferencaEmMilissegundos - 7 * 1000,
          message: 'Atenção, sua sessão encerra em 7 segundos',
          duration: 1000,
          action: () => '',
        },
        {
          time: diferencaEmMilissegundos - 6 * 1000,
          message: 'Atenção, sua sessão encerra em 6 segundos',
          duration: 1000,
          action: () => '',
        },
        {
          time: diferencaEmMilissegundos - 5 * 1000,
          message: 'Atenção, sua sessão encerra em 5 segundos',
          duration: 1000,
          action: () => '',
        },
        {
          time: diferencaEmMilissegundos - 4 * 1000,
          message: 'Atenção, sua sessão encerra em 4 segundos',
          duration: 1000,
          action: () => '',
        },
        {
          time: diferencaEmMilissegundos - 3 * 1000,
          message: 'Atenção, sua sessão encerra em 3 segundos',
          duration: 1000,
          action: () => '',
        },
        {
          time: diferencaEmMilissegundos - 2 * 1000,
          message: 'Atenção, sua sessão encerra em 2 segundos',
          duration: 1000,
          action: () => '',
        },
        {
          time: diferencaEmMilissegundos - 1 * 1000,
          message: 'Atenção, sua sessão encerra em 1 segundo',
          duration: 1000,
          action: () => '',
        },
        {
          time: diferencaEmMilissegundos - 0 * 1000,
          message: 'Sessão Encerrada!',
          duration: 1000,
          action: () => {
            dispatch(userSignOut());
          },
        },
      ];

      if (authUserInfo.horarios.ativo) {
        warnings.forEach((warning) => {
          if (warning.time > 0)
            countdown(
              warning.time,
              warning.message,
              warning.duration,
              warning.action
            );
        });
      }
    }
  };

  const getColorTheme = (themeColor2, applyTheme) => {
    switch (themeColor2) {
      case INDIGO: {
        applyTheme = createTheme(indigoTheme);
        break;
      }
      case CYAN: {
        applyTheme = createTheme(cyanTheme);
        break;
      }
      case AMBER: {
        applyTheme = createTheme(amberTheme);
        break;
      }
      case DEEP_ORANGE: {
        applyTheme = createTheme(orangeTheme);
        break;
      }
      case PINK: {
        applyTheme = createTheme(pinkTheme);
        break;
      }
      case BLUE: {
        applyTheme = createTheme(blueTheme);
        break;
      }
      case DEEP_PURPLE: {
        applyTheme = createTheme(purpleTheme);
        break;
      }
      case GREEN: {
        applyTheme = createTheme(greenTheme);
        break;
      }
      case DARK_INDIGO: {
        // applyTheme = createTheme({ ...indigoTheme, direction: 'rtl' });
        applyTheme = createTheme(indigoTheme);
        break;
      }
      case DARK_CYAN: {
        applyTheme = createTheme(cyanTheme);
        break;
      }
      case DARK_AMBER: {
        applyTheme = createTheme(amberTheme);
        break;
      }
      case DARK_DEEP_ORANGE: {
        applyTheme = createTheme(orangeTheme);
        break;
      }
      case DARK_PINK: {
        applyTheme = createTheme(pinkTheme);
        break;
      }
      case DARK_BLUE: {
        applyTheme = createTheme(blueTheme);
        break;
      }
      case DARK_DEEP_PURPLE: {
        applyTheme = createTheme(purpleTheme);
        break;
      }
      case DARK_GREEN: {
        applyTheme = createTheme(greenTheme);
        break;
      }
      default:
        createTheme(blueTheme);
    }
    return applyTheme;
  };

  let applyTheme = createTheme(blueTheme);
  if (isDarkTheme) {
    document.body.classList.add('dark-theme');
    applyTheme = createTheme(darkTheme);
  } else {
    applyTheme = getColorTheme(themeColor, applyTheme);
  }

  if (location.pathname === '/') {
    if (authUser === null) {
      return <Redirect to="/login" />;
    }

    if (initURL === '' || initURL === '/' || initURL === '/login') {
      return <Redirect to="/app/cob" />;
    }

    return <Redirect to={initURL} />;
  }

  if (
    location.pathname === '/login' &&
    (isAuthenticated() == true || getToken2FA(authUser2FA))
  ) {
    // if (window.history.state) {
    //   const { state: backHistory } = window.history.state;
    //   if (backHistory && backHistory.from && backHistory.from.pathname) {
    //     rotaInicio = backHistory.from.pathname;
    //   }
    // }

    /* Se possuir 2FA ele redireciona para essa rota e caso não tenha ele vai 
    tentar acessar a rota /twofa 
    mas vai ser barrado pela validação logo acima na linha 200 pois a rota /twofa é uma rota de acesso de segurança
    ela funciona da mesma forma que a rota /login que após ser logado e tentar acessar esssa rota ele sempre irá
    redirecionar para a rota principal /app/cob. */

    let rotaInicio = '/twofa';

    // if (window.history.state) {
    //  const { state: backHistory } = window.history.state;
    //  if (backHistory && backHistory.from && backHistory.from.pathname) {
    //    rotaInicio = backHistory.from.pathname;
    //  }
    // }

    return <Redirect to={rotaInicio} />;
  }

  if (location.pathname === '/twofa' && isAuthenticated() == true) {
    let rotaInicio = '/app/cob';

    return <Redirect to={rotaInicio} />;
  }

  const currentAppLocale = AppLocale[locale.locale];
  return (
    <ThemeProvider theme={applyTheme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
        >
          <RTL>
            <div className="app-main">
              <Switch>
                <RestrictedRoute
                  path={`${match.url}app`}
                  authUser={authUser}
                  component={AppLayout}
                />
                <Route path="/login" component={SignIn} />
                <Route path="/twofa" component={Code2FA} />
                <Route component={erro404} />
              </Switch>
            </div>
          </RTL>
        </IntlProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default function IntegrationNotistack_App(props) {
  return (
    <SnackbarProvider
      maxSnack={5}
      autoHideDuration={3000}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <App {...props} />
    </SnackbarProvider>
  );
}
