import React, { useEffect, useState } from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogActions,
  DialogContent,
  Button,
} from '@material-ui/core';
import api from 'services/api';
import SnackbarNotification from 'components/SnackbarNotification';

export default function RejeitarAlega({
  abrirDialog,
  callbackParentFechar,
  callbackListarAlega,
  rowSelecionada,
}) {
  const [idDevedor, setIdDevedor] = useState();
  const [idCredor, setIdCredor] = useState();
  const [processo, setProcesso] = useState();
  const [hashAlega, setHashAlega] = useState();
  const [openAlert, setOpenAlert] = useState(false);
  const [alertInfoTipo, setAlertInfoTipo] = useState('');
  const [alertInfo, setAlertInfo] = useState('');

  useEffect(() => {
    if (abrirDialog && rowSelecionada) {
      setIdDevedor(rowSelecionada.iddevedor);
      setIdCredor(rowSelecionada.idcredor);
      setProcesso(rowSelecionada.processo);
      setHashAlega(rowSelecionada.id_uuid);
    }
  }, [abrirDialog, rowSelecionada]);

  async function rejeitarAlega() {
    try {
      const data = {
        iddevedor: idDevedor,
        idcredor: idCredor,
        processo,
        hash_alega: hashAlega,
      };
      const response = await api.post('/rejeitar_alega_gestao', { data });
      if (response.status === 200) {
        callbackParentFechar();
        callbackListarAlega();
        setAlertInfo('Rejeitado com sucesso');
        setAlertInfoTipo('mb-3 bg-success');
        setOpenAlert(true);
      }
    } catch (error) {
      setAlertInfo('Erro ao rejeitar a alegação de pagamento!');
      setAlertInfoTipo('mb-3 bg-danger');
      setOpenAlert(true);
    }
  }

  return (
    <>
      {abrirDialog ? (
        <Dialog
          open={abrirDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Confirmar a rejeição
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Confirma a rejeição do registro selecionado?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => rejeitarAlega()} color="primary">
              Confirmar Rejeição
            </Button>
            <Button onClick={() => callbackParentFechar()} autoFocus>
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}

      <SnackbarNotification
        openAlert={openAlert}
        alertInfoTipo={alertInfoTipo}
        alertInfo={alertInfo}
        callbackParent={() => setOpenAlert(false)}
      />
    </>
  );
}
