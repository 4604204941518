/* eslint eqeqeq: 0 */

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const Error404 = () => {
  let toRedireciona = '/app/cob';

  useEffect(() => {
    const tipoMenu = localStorage.getItem('user_menu');

    if (tipoMenu == 'rel') toRedireciona = '/app/rel';
    else if (tipoMenu == 'cad') toRedireciona = '/app/cad';
    else if (tipoMenu == 'fin') toRedireciona = '/app/fin';
    else if (tipoMenu == 'conf') toRedireciona = '/app/config';
    else if (tipoMenu == 'backof') toRedireciona = '/app/backof';
    else if (tipoMenu == 'com') toRedireciona = '/app/com';
    else if (tipoMenu == 'cob') toRedireciona = '/app/cob';
  }); // nao esta funcionando

  return (
    <div className="app-wrapper page-error-container animated slideInUpTiny animation-duration-3">
      <div className="page-error-content">
        <div className="error-code mb-4 animated zoomInDown">404</div>
        <h2 className="text-center fw-regular title bounceIn animation-delay-10 animated">
          Página não encontrada
        </h2>
        <p className="text-center zoomIn animation-delay-20 animated">
          <Link className="btn btn-primary" to={toRedireciona}>
            Voltar ao Início
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Error404;
