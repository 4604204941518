import React, { useCallback, useEffect, useState } from 'react';

import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro';
import { IconButton, Box, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';

import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import SaveIcon from '@material-ui/icons/Save';
import ReplayIcon from '@material-ui/icons/Replay';

import { useSnackbar } from 'notistack';

function customCheckbox(theme) {
  return {
    '& .MuiCheckbox-root svg': {
      width: 18,
      height: 18,
      backgroundColor: 'transparent',
      border: `0px solid ${
        theme.palette.mode === 'light' ? '#d9d9d9' : 'rgb(67, 67, 67)'
      }`,
      borderRadius: 2,
    },
    /*
    '& .MuiCheckbox-root svg path': {
      display: 'none',
    },
    '& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg': {
      backgroundColor: '#1890ff',
      borderColor: '#1890ff',
    },
    '& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after': {
      position: 'absolute',
      display: 'table',
      border: '2px solid #fff',
      borderTop: 0,
      borderLeft: 0,
      transform: 'rotate(45deg) translate(-50%,-50%)',
      opacity: 1,
      transition: 'all .2s cubic-bezier(.12,.4,.29,1.46) .1s',
      content: '""',
      top: '50%',
      left: '39%',
      width: 5.71428571,
      height: 9.14285714,
    },
    */
  };
}

const StyledDataGridPro = styled(DataGridPro)(({ theme, headerTop }) => ({
  border: 0,
  color:
    theme.palette.mode === 'light'
      ? 'rgba(0,0,0,.85)'
      : 'rgba(255,255,255,0.85)',
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  fontSize: '13px',
  WebkitFontSmoothing: 'auto',
  letterSpacing: 'normal',
  '& .MuiDataGrid-columnsContainer': {
    backgroundColor: theme.palette.mode === 'light' ? '#fafafa' : '#1d1d1d',
  },
  '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
    borderBottom: `1px solid ${
      theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
    }`,
  },
  '& .MuiDataGrid-cell': {
    color:
      theme.palette.mode === 'light'
        ? 'rgba(0,0,0,.85)'
        : 'rgba(255,255,255,0.65)',
  },
  '& .MuiPaginationItem-root': {
    borderRadius: 0,
  },
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: '#f5f5f5',
    //position: headerTop >= 70 ? 'fixed' : 'relative',
    //top: headerTop >= 70 ? 70 : 0,
    //zIndex: 1000,
  },
  '& .MuiTablePagination-selectLabel': {
    paddingTop: '13px',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  '& .MuiTablePagination-displayedRows': {
    paddingTop: '13px',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  '& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus, .MuiDataGrid-cell:focus-within':
    {
      outline: 'none',
    },
  ...customCheckbox(theme),
}));

/*
const StyledGridColumnMenu = styled(GridColumnMenu)(() => ({
  backgroundColor: 'red',
  color: 'white',
  fontSize: '11px',
}));

export function CustomColumnMenuComponent(props) {
  const { hideMenu, colDef, color, ...other } = props;

  return (
    <StyledGridColumnMenu
      hideMenu={hideMenu}
      colDef={colDef}
      ownerState={{ color }}
      {...other}
    ></StyledGridColumnMenu>
  );
}
*/

const CustomDataGridPro = ({
  name,
  enabledSaveState,
  data,
  columns,
  qtdeReg,
  paginationModel,
  setPaginationModel,
  onSortModelChange,
  getRowId,
  loading,
  scrollBarTop,
  sortColumnDefault,
  columnVisibilityModel,
  setColumnVisibilityModel,
  getCountFull,
  currentPage,
  callbackSetPage,
  changeData,
  paginationMode,
  checkboxSelection,
  rowSelectionModel,
  onRowSelectionModelChange,
}) => {
  const apiRef = useGridApiRef();
  const { enqueueSnackbar } = useSnackbar();

  const [initialState, setInitialState] = useState(data.initialState);

  function TablePaginationActions(props) {
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      //onPageChange(event, 0);
      callbackSetPage(0);
    };

    const handleBackButtonClick = (event) => {
      //onPageChange(event, page - 1);
      callbackSetPage(currentPage - 1);
    };

    const handleNextButtonClick = (event) => {
      //onPageChange(event, page + 1);
      callbackSetPage(currentPage + 1);
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={currentPage <= 0}
          aria-label="Primeira Página"
        >
          <FirstPageIcon fontSize="small" />
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={currentPage <= 0}
          aria-label="Página Anterior"
        >
          <KeyboardArrowLeft fontSize="small" />
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={
            getCountFull
              ? page >= Math.ceil(count / rowsPerPage) - 1
              : data.length === 0 || data.length < rowsPerPage
          }
          aria-label="Próxima Página"
        >
          <KeyboardArrowRight fontSize="small" />
        </IconButton>

        {enabledSaveState && (
          <>
            <Tooltip title="Salvar Colunas" arrow>
              <IconButton
                className="ml-4"
                onClick={saveStateDataGrid}
                aria-label="Salvar"
              >
                <SaveIcon fontSize="small" color="primary" />
              </IconButton>
            </Tooltip>

            <Tooltip title="Redefinir Colunas" arrow>
              <IconButton
                className="mr-2"
                onClick={resetStateDataGrid}
                aria-label="Redefinir"
              >
                <ReplayIcon fontSize="small" color="primary" />
              </IconButton>
            </Tooltip>
          </>
        )}
      </Box>
    );
  }

  const saveStateDataGrid = useCallback(() => {
    const currentState = apiRef.current.exportState();
    localStorage.setItem(`dataGridState${name}`, JSON.stringify(currentState));

    enqueueSnackbar(`Colunas Salvas com Sucesso`, {
      variant: 'success',
    });
  }, [apiRef]);

  function loadStateDataGrid() {
    const stringifiedState = localStorage.getItem(`dataGridState${name}`);
    return new Promise((resolve) => {
      if (!stringifiedState) {
        return resolve({});
      }
      const stateSnapshot = JSON.parse(stringifiedState);
      return resolve(stateSnapshot);
    });
  }

  function resetStateDataGrid() {
    apiRef.current.restoreState(initialState);
    localStorage.removeItem(`dataGridState${name}`);

    enqueueSnackbar(`Colunas Redefinidas com Sucesso`, {
      variant: 'success',
    });
  }

  function isEmpty(obj) {
    return !Object.keys(obj).length > 0;
  }

  //useLayoutEffect(() => {
  useEffect(() => {
    const loadState = async () => {
      const stateRestore = await loadStateDataGrid();
      if (isEmpty(stateRestore) == false) {
        const columnsRestore = stateRestore.columns;
        const pinnedColumnsRestore = stateRestore.pinnedColumns;
        const sortingRestore = stateRestore.sorting;
        apiRef.current.restoreState({
          columns: columnsRestore,
          pinnedColumns: pinnedColumnsRestore,
          sorting: sortingRestore,
        });
      }
    };

    const currentState = apiRef.current.exportState();
    setInitialState(currentState);

    loadState();
  }, []);

  return (
    <StyledDataGridPro
      apiRef={apiRef}
      loading={loading}
      /*
      slots={{
        columnMenu: CustomColumnMenuComponent,
        columnMenuUserItem: 
      }}
      */
      slotProps={{
        pagination: {
          ActionsComponent: TablePaginationActions,
          //showFirstButton: true,
          labelRowsPerPage: 'Registros por Página',
          labelDisplayedRows: ({ from, to, count }) => {
            return getCountFull ? `${from}-${to} de ${count}` : '';
          },
        },
        panel: {
          sx: {
            '& .MuiDataGrid-columnsPanel': {
              height: '200px',
            },
          },
        },
      }}
      hideScrollbar
      headerTop={scrollBarTop}
      initialState={{
        ...data.initialState,
        sorting: {
          ...data.initialState?.sorting,
          sortModel: [
            {
              field: sortColumnDefault,
              sort: 'asc',
            },
          ],
        },
        columns: {
          columnVisibilityModel: columnVisibilityModel || {},
        },
      }}
      keepNonExistentRowsSelected
      checkboxSelection={checkboxSelection || false}
      rowSelectionModel={rowSelectionModel}
      onRowSelectionModelChange={onRowSelectionModelChange}
      disableColumnFilter
      columns={columns}
      rows={data}
      disableSelectionOnClick
      disableRowSelectionOnClick
      columnHeaderHeight={scrollBarTop > 75 ? 40 : 35}
      rowHeight={30}
      getRowId={getRowId}
      sortingMode="server"
      onSortModelChange={onSortModelChange}
      pagination
      paginationMode={paginationMode ? paginationMode : "server"}
      rowCount={qtdeReg}
      pageSizeOptions={[5, 10, 25, 50, 100]}
      onPaginationModelChange={setPaginationModel}
      sortingOrder={['asc', 'desc']}
      paginationModel={paginationModel}
      //columnVisibilityModel={columnVisibilityModel}
      //onColumnVisibilityModelChange={setColumnVisibilityModel}
      //locale={ptBR.components.MuiDataGrid.defaultProps.localeText}
      localeText={{
        // https://mui.com/x/react-data-grid/localization/
        noRowsLabel: '',
        noResultsOverlayLabel: '',
        // Columns panel text
        columnsPanelTextFieldLabel: 'Localizar coluna',
        columnsPanelTextFieldPlaceholder: 'Título da coluna',
        columnsPanelDragIconLabel: 'Reordenar Coluna',
        columnsPanelShowAllButton: 'Mostrar Todas',
        columnsPanelHideAllButton: 'Ocultar Todas',
        // Column menu text
        columnMenuLabel: 'Menu',
        columnMenuShowColumns: 'Exibir Colunas',
        columnMenuManageColumns: 'Gerenciar Colunas',
        columnMenuFilter: 'Filtrar',
        columnMenuHideColumn: 'Ocultar',
        columnMenuUnsort: 'Desfazer Ordenação',
        columnMenuSortAsc: 'Ordenar Crescente',
        columnMenuSortDesc: 'Ordenar Decrescente',
        footerRowSelected: (count) =>
          count !== 1
            ? `${count.toLocaleString()} linhas selecionadas`
            : `${count.toLocaleString()} linha selecionada`,
        // Column header text
        columnHeaderFiltersTooltipActive: (count) =>
          `${count} ${count !== 1 ? 'filtros' : 'filtro'} ${
            count !== 1 ? 'ativos' : 'ativo'
          }`,
        columnHeaderFiltersLabel: 'Exibir Filtros',
        columnHeaderSortIconLabel: 'Ordenar',
        pinToLeft: 'Fixar à Esquerda',
        pinToRight: 'Fixar à Direita',
        unpin: 'Desafixar',
      }}
    />
  );
};

export default CustomDataGridPro;
